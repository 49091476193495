import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: {
        "Car Listings": "Car Listings",
        Earnings: "Earnings",
        "Listing Request": "Listing Request",
        Language: "Language",
        "Sign out": "Sign out",
        Logout: "Logout",
        Dispatcher: "Dispatcher",
        Officer: "Officer",
        "Car Details": "Car Details",
        "Edit Car": "Edit Car",
        "Add New Car": "Add New Car",
        "Edit Car Information": "Edit Car Information",
        "Submit to qc": "Submit to qc",
        "Submit Form": "Submit Form",
        "Submit & Exit": "Submit & Exit",
        Exit: "Exit",
        Submit: "Submit",
        "email already used": "email already used",
        "Sort By": "Sort By",
        Sort: "Sort",
        "Date (Oldest First)": "Date (Oldest First)",
        "Date (Newest First)": "Date (Newest First)",
        "Price (High to Low)": "Price (High to Low)",
        "Price (Low to High)": "Price (Low to High)",
        "Score (High to Low)": "Score (High to Low)",
        "Score (Low to High)": "Score (Low to High)",
        "Odometer (Low to High)": "Odometer (Low to High)",
        "Odometer (High to Low)": "Odometer (High to Low)",
        "Year (Oldest First)": "Year (Oldest First)",
        "Year (Newest First)": "Year (Newest First)",
        Date: "Date",
        DateFilter: "Date",
        Price: "Price",
        Score: "Score",
        ScoreFilter: "Score",
        Milage: "Milage",
        Year: "Year",
        "(Oldest First)": "(Oldest First)",
        "(Newest First)": "(Newest First)",
        "(Low to High)": "(Low to High)",
        "(High to Low)": "(High to Low)",
        "(Lowest First)": "(Lowest First)",
        "(Highest First)": "(Highest First)",
        "Condition Score": "Condition Score",
        Results: "Results",
        Odometer: "Odometer",
        OdometerFilter: "Odometer",
        Location: "Location",
        "Oldest Listings First": "Oldest Listings First",
        "Newest Listings First": "Newest Listings First",
        "Highest Price First": "Highest Price First",
        "Lowest Price First": "Lowest Price First",
        "Highest Score First": "Highest Score First",
        "Lowest Score First": "Lowest Score First",
        "Number of listings": "Number of listings",
        "Vehicle Details": "Vehicle Details",
        "Vehicle Features": "Vehicle Features",
        "No Features available for this car!":
          "No Features available for this car!",
        "Markabte Inspection Score": "Markabte Inspection Score",
        "View Full Report": "View Full Report",
        "Edit Car": "Edit Car",
        Listing: "Listing",
        "out of": "out of",
        "Inspection Report": "Inspection Report",
        "Markabte Inspection Details": "Markabte Inspection Details:",
        "Inspection Date": "Inspection Date :",
        "Inspected By": "Inspected By :",
        "Inspection Points": "Inspection Points :",
        "Overall Inspection Score": "Overall Inspection Score :",
        "Detailed Inspection Points": "Detailed Inspection Points :",
        "Show inspection points with faults only":
          "Show inspection points with faults only",
        "Markabte Officer Comments": "Markabte Officer Comments :",
        "No comments": "No comments!",
        "Back To Vehicle Detail Page": "Back To Vehicle Detail Page",
        "Incoming Requests": "Incoming Requests",
        "Claimed Requests": "Claimed Requests",
        submitted: "submitted",
        "hour ago": "hour ago",
        Claim: "Claim",
        continue: "Continue",
        GPS: "GPS",
        "List Vehicle": "List Vehicle",
        Listed: "Listed",
        "days ago": "days ago",
        Mile: "Mile",
        Km: "Km",
        JD: "JD",
        "english JD": "JD ",
        "arabic JD": "",
        "/ Month .": "/ Month .",
        " months . JD": " months . JD",
        "K down": "K down",
        "View Vehicle": "View Vehicle",
        publish: "publish",
        "View Exterior": "View Exterior",
        "View Interior": "View Interior",
        "Driver Front": "Driver Front",
        "Passenger Rear": "Passenger Rear",
        Front: "Front",
        Back: "Back",
        "3rd row": "3rd row",
        Trunk: "Trunk",
        "dealer staff": "dealer staff",
        "dealer owner": "dealer owner",
        "Edit Price": "Edit Price",
        "Report Sale": "Report Sale",
        "Please select the type of user this vehicle was sold to!":
          "Please select the type of user this vehicle was sold to!",
        "Independent Customer": "Independent Customer",
        "Markabte User": "Markabte User",
        "Edit car price": "Edit car price",
        "Request Listing Entry": "Request Listing Entry",
        "Request Officers for Vehicle Listing":
          "Request Officers for Vehicle Listing",
        "Please specify the number of vehicles that need to be listed by our officers":
          "Please specify the number of vehicles that need to be listed by our officers!",
        "Number Of Vehicles": "Number Of Vehicles",
        "Verify Your Mobile Number": "Verify Your Mobile Number",
        Confirm: "Confirm",
        Close: "Close",
        "Sign In": "Sign In",
        Email: "Email",
        Password: "Password",
        PassConfirm: "Password confirm",
        "incorrect password": "incorrect password",
        "Save Changes": "Save Changes",
        "Delete Appointment": "Delete Appointment",
        "Appointment Updated Successfuly": "Appointment Updated Successfuly",
        "process done successfuly": "process have been done successfuly",
        "forgot password": "forgot password ?",
        "Hurray, No issues were found": "Hurray, No issues were found!",
        Exterior: "Exterior",
        Interior: "Interior",
        "Drive Front View": "Drive Front View",
        "Passenger Rear View": "Passenger Rear View",
        "3rd Row View": "3rd Row View",
        "Trunk View": "Trunk View",
        "Rear View": "Rear View",
        "Front View": "Front View",
        Major: "Major",
        Minor: "Minor",
        "Vehicle Details Page": "Vehicle Details Page",
        "Vehicle Information": "Vehicle Information",
        "Vehicle Condition": "Vehicle Condition",
        "Show full report": "Show full report",
        "Inspection Points": "Inspection Points",
        "Based on our FREE ": "Based on our FREE ",
        "+ point condition report": "+ point condition report",
        Incoming: "Incoming",
        Claimed: "Claimed",
        "List Car": "List Car",
        "Join Our Netwrok": "Join Our Netwrok",
        "Edit Content": "Edit Content",
        "Publish Vehicle": "Publish Vehicle",
        "Publish Vehicle?": "Publish Vehicle?",
        "Are you sure that you want to publish the vehicle listing?":
          "Are you sure that you want to publish the vehicle listing?",
        Cancel: "Cancel",
        of: "of",
        "Front Angle": "Front Angle",
        "Rear Angle": "Rear Angle",
        Driver: "Driver",
        "Driver View": "Driver View",
        Passenger: "Passenger",
        "Passenger View": "Passenger View",
        "3rd row": "3rd Row",
        Trunk: "Trunk",
        "Angle Issues List": "Angle Issues List",
        "View List": "View List",
        "forgot password": "Forgot Password?",
        "Forgot password?": "Forgot Password?",
        "Send Reset Link": "Send Reset Link",
        "Minor Issues": "Minor Issues",
        "Major Issues": "Major Issues",
        "Please enter your email and we will send you a reset link.":
          "Please enter your email and we will send you a reset link.",
        "Forgot Your Password?": "Forgot Your Password?",
        "Forgot your password?": "Forgot your password?",
        Connect: "Connect",
        "with serious car buyers.": "with serious car buyers.",
        "Back to Sign In": "Back to Sign In",
        "Please enter your new password.": "Please enter your new password.",
        "New Password": "New Password",
        "Confirm Password": "Confirm Password",
        "Reset Your Password": "Reset Your Password",
        "Reset Password": "Reset Password",
        "Sign In to Your Account": "Sign In to Your Account",
        "Keep me signed in": "Keep me signed in",
        "What do you want to do next?": "What do you want to do next?",
        "Vehicle Condition Report": "Vehicle Condition Report",
        "Inspection Report Summary": "Inspection Report Summary",
        "Markabte Officer": "Markabte Officer",
        "Inspected On": "Inspected On",
        "Report Summary": "Report Summary",
        "Vehicle Visual Condition": "Vehicle Visual Condition",
        "Click on the colored markers to see issue details.":
          "Click on the colored markers to see issue details.",
        Note: "Note",
        "View Visual Cosmetic Report": "View Visual Cosmetic Report",
        "View Visual Report": "View Visual Report",
        "Visual Report": "Visual Report",
        "Markabte Full Condition Report": "Markabte Full Condition Report",
        Disclaimer: "Disclaimer",
        "Report Car Sale": "Report Car Sale",
        "You are successfully connected to live changes":
          "You are successfully connected to live changes",
        "Please make sure you entered valid credentials":
          "Please make sure you entered valid credentials",
        "Your passwords do not match!": "Your passwords do not match!",
        "Password must be 8 characters. Minimum one number and one letter, without spaces.":
          "Password must be 8 characters. Minimum one number and one letter, without spaces.",
        "account activated! please login with your new password":
          "account activated! please login with your new password",
        error: "error",
        "Your password reset reqest has been sent to your email.":
          "Your password reset reqest has been sent to your email.",
        "Please make sure you entered a valid email address":
          "Please make sure you entered a valid email address",
        "Car sale status successfully updated":
          "Car sale status successfully updated",
        "Oops! an error has occured! please try again.":
          "Oops! an error has occured! please try again.",
        "Your changes have been saved": "Your changes have been saved",
        "There was an error sending your request please try again":
          "There was an error sending your request please try again",
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s exterior":
          "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s exterior",
        "We measure and analyze the vehicle’s paint thickness readings to identify repainted panels from panels with original paint.":
          "We measure and analyze the vehicle’s paint thickness readings to identify repainted panels from panels with original paint.",
        "We measure temperature of cooling/heating systems & check air throw for all AC vents.":
          "We measure temperature of cooling/heating systems & check air throw for all AC vents.",
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s interior":
          "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s interior",
        "We test the functionality of various control parts of the vehicle (Buttons, Controls, Systems) to identify what works and what does not":
          "We test the functionality of various control parts of the vehicle (Buttons, Controls, Systems) to identify what works and what does not",
        "We scan the vehicle’s on-board computer to diagnose and identify fault codes within mechanical and electrical systems of the vehicle":
          "We scan the vehicle’s on-board computer to diagnose and identify fault codes within mechanical and electrical systems of the vehicle",
        "Vehicle’s frame inspection report is provided by a 3rd inspection party, please take note of the inspection’s date":
          "Vehicle’s frame inspection report is provided by a 3rd inspection party, please take note of the inspection’s date",
        "We determine the overall condition based on the remaining tread depth, age, and the tire’s visual inspection results (cracking, bulges)":
          "We determine the overall condition based on the remaining tread depth, age, and the tire’s visual inspection results (cracking, bulges)",
        "We check the availability of standard equipment in the vehicle":
          "We check the availability of standard equipment in the vehicle",
        "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking.":
          "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking.",
        Excellent: "Excellent",
        "Minor Issue": "Minor Issue",
        "Major Issue": "Major Issue",
        "This Angle has no Minor issue": "This Angle has no Minor issue",
        "This Angle has no Major issue": "This Angle has no Major issue",
        "Origional Paint": "Origional Paint",
        Repainted: "Repainted",
        Working: "Working",
        "Frame Issues": "Issues",
        "Not Working": "Not Working",
        "Additional Information": "Additional Information",
        "No codes": "No codes",
        "Minor issues": "Minor issues",
        "Major issues": "Major issues",
        Monitor: "Monitor",
        Replace: "Replace",
        Available: "Available",
        "Not Available": "Not Available",
        "Available Cars Only": "Available Cars Only",
        "Welcome Back!": "Welcome Back!",
        firstArabicLoginText: "",
        secondArabicLoginText: "",
        DEDICATED: "DEDICATED",
        "officers at your fingertips,": "officers at your fingertips,",
        QUALIFIED: "QUALIFIED",
        "buyers to your dealership.": "buyers to your dealership.",
        "There where no Major issues spotted in this view!":
          "There where no Major issues spotted in this view!",
        "There where no Minor issues spotted in this view!":
          "There where no Minor issues spotted in this view!",
        "The maximum number of vehicles is 50":
          "The maximum number of vehicles is 50",
        Home: "Home",
        "All Results": "All Results",
        "Save Search": "Save Search",
        "Book An Appointment": "Book An Appointment",
        "please enter city": "please enter city",
        "Go To Homepage": "Go To Homepage",
        Homepage: "Homepage",
        "We couldn’t find the page you are looking for.":
          "We couldn’t find the page you are looking for.",
        amman: "Amman",
        aqaba: "Aqaba",
        jerash: "Jerash",
        irbid: "Irbid",
        madaba: "Madaba",
        zarqa: "Zarqa",
        kerak: "Kerak",
        mafraq: "Mafraq",
        ajloun: "Ajloun",
        salt: "Salt",
        "ma'an": "Ma'an",
        "at-tafilah": "Tafilah",
        "New Search": "New Search",
        Filter: "Filter",
        "Active Filters": "Active Filters",
        Explore: "Explore",
        Homepage: "Homepage",
        "Used Cars": "Used Cars",
        "Inspection Process": "Inspection Process",
        FAQ: "FAQ",
        "Our Company": "Our Company",
        "About Markabte": "About Markabte",
        "Markabte Officer": "Markabte Officer",
        "Contact us": "Contact us",
        Careers: "Careers",
        "Dealership Network": "Dealership Network",
        Legal: "Legal",
        "Privacy Policy": "Privacy Policy",
        "Terms & Conditions": "Terms & Conditions",
        "Follow Us": "Follow Us",
        "Used Cars": "Used Cars",
        "Inspection Process": "Inspection Process",
        dealername: "dealername",
        FAQ: "FAQ",
        More: "More",
        "My Account": "My Account",
        "Update Account": "Update Account",
        "Saved Searches": "Saved Searches",
        "Saved Listing": "Saved Listing",
        "My Appointments": "My Appointments",
        "Account Settings": "Account Settings",
        Explore: "Explore",
        Homepage: "Homepage",
        "Used Cars": "Used Cars",
        "Inspection Process": "Inspection Process",
        FAQ: "FAQ",
        "Our Company": "Our Company",
        "About Markabte": "About Markabte",
        "Markabte Officer": "Markabte Officer",
        "Contact us": "Contact us",
        Careers: "Careers",
        "Dealership Network": "Dealership Network",
        Legal: "Legal",
        "Privacy Policy": "Privacy Policy",
        "Terms & Conditions": "Terms & Conditions",
        "Follow Us": "Follow Us",
        "Used Cars": "Used Cars",
        "Inspection Process": "Inspection Process",
        FAQ: "FAQ",
        More: "More",
        Arabic: "العربية",
        "My Account": "My Account",
        Myself: "Myself",
        "Someone Else": "Someone Else",
        Change: "Change",
        Next: "Next",
        "I agree that the dealer will photograph the car license if I buy the car.":
          "I agree that the dealer will photograph the car license if I buy the car.",
        "Dealership is currently closed!": "Dealership is currently closed!",
        "Saved Searches": "Saved Searches",
        "Saved Listing": "Saved Listing",
        "My Appointments": "My Appointments",
        "Account Settings": "Account Settings",
        "Search For Car": "Search For Car",
        "Explore Pre-inspected Vehicles.": "Explore Pre-inspected Vehicles.",
        "For Free": "For Free",
        "Markabte Difference": "Markabte Difference",
        "Recently Listed Cars": "Recently Listed Cars",
        "Why Choose Us?": "Why Choose Us?",
        "Choose Arrival Time": "Choose Arrival Time",
        "Pre-Inspected Vehcles": "Pre-Inspected Vehcles",
        "Trust and Transparency": "Trust and Transparency",
        Free: "Free",
        "Learn More >": "Learn More >",
        "Detailed, comprehensive and user friendly report available upfront.":
          "Detailed, comprehensive and user friendly report available upfront.",
        "Independent officers, inspect cars using advanced equipment and professional techniques.":
          "Independent officers, inspect cars using advanced equipment and professional techniques.",
        "Browse cars, connect with sellers and buy your car for FREE!":
          "Browse cars, connect with sellers and buy your car for FREE!",
        "How It Works": "How It Works",
        "Book Your Appointment": "Book Your Appointment",
        "Visit Seller & Check The Car": "Visit Seller & Check The Car",
        "Buy Your Desired Car": "Buy Your Desired Car",
        BookAppointmentText:
          "Book your appointment to view the car in person anytime!",
        VisitSellerText:
          "Meet the seller and have an up close look at your future car.",
        BuyCarText: "Seal the deal with the seller and celebrate your new car!",
        NotifyUsText: "Do not forget to notify us about your car purchase.",
        "By Make/Model": "By Make/Model",
        "By Fuel Type": "By Fuel Type",
        "All Makes": "All Makes",
        Models: "Models",
        "All Fuel Types": "All Fuel Types",
        "Year (From)": "Year (From)",
        "Year (To)": "Year (To)",
        "Price (From)": "Price (From)",
        "Price (To)": "Price (To)",
        "Search For Cars": "Search For Cars",
        authenticatedAppointmentsParagraph:
          "You can manage your appointments, access dealer information and location information from your account.",
        "Interested In This Car ?": "Interested In This Car ?",
        "Use your points to unlock the seller’s information.":
          "Use your points to unlock the seller’s information.",
        "Book Free Appointment": "Book Free Appointment",
        "Sign Up For an Account": "Sign Up For an Account",
        "1.Book Your Appointment": "1.Book Your Appointment",
        "2.Visit Seller & Check The Car": "2.Visit Seller & Check The Car",
        "3.Buy Your Desired Car": "3.Buy Your Desired Car",
        "4.Notify Us": "4.Notify Us",
        "How it works": "How it works",
        phone: "phone",
        Phone: "Phone",
        email: "email",
        "Email Address": "Email Address",
        "Invalid Credentials": "Invalid Credentials",
        "First Name": "First Name",
        Female: "Female",
        Male: "Male",
        Delete: "Delete",
        "No, but want to re-schedule? Re-schedule":
          "No, but want to re-schedule? Re-schedule",
        "No, but want to re-schedule?": "No, but want to re-schedule?",
        "Re-schedule": "Re-schedule",
        "Did you go to the following appointment?":
          "Did you go to the following appointment?",
        "Confirm Attendance": "Confirm Attendance",
        "No, I Didn’t Go": "No, I Didn’t Go",
        "Yes, I Went": "Yes, I Went",
        "Deleted appointments cannot be retrevied.":
          "Deleted appointments cannot be retrevied.",
        "Are you sure you want to delete the appointment?":
          "Are you sure you want to delete the appointment?",
        "Gender (Optional)": "Gender (Optional)",
        "Last Name": "Last Name",
        "password does not match confirmation":
          "password does not match confirmation",
        "Passwords must match.": "Passwords must match.",
        "invalid mobile number": "invalid mobile number",
        "this field is required": "this field is required",
        "invalid Email address": "invalid Email address",
        "View All": "View All",
        disclaimerParagraph:
          "Call 042 - 111 WHEELS (042- 111 943 357) for pre-delivery inspection of PakWheels Certified Car. Incase of failure to perform pre-delivery inspection, PakWheels.com will not be ye, hence it can be possible for such paint to go undetected.",
        "Visual Inspection Report": "Visual Inspection Report",
        "View Faults Only": "View Faults Only",
        faults: "faults",
        "No Faults": "No Faults",
        "Show more": "Show more",
        "Show less": "Show less",
        Kilometer: "Kilometer",
        "2021 Markabte. All Rights Reserved.":
          "2021 Markabte. All Rights Reserved.",
        "All Options": "All Options",
        "0 listings match your search criteria. The following search criteria(s) have been removed:":
          "0 listings match your search criteria. The following search criteria(s) have been removed:",
        removedCriteriaText: "No cars match all search criteria.",
        black: "black",
        white: "white",
        silver: "silver",
        grey: "grey",
        red: "red",
        blue: "blue",
        green: "green",
        brown: "brown",
        beige: "beige",
        burgundy: "burgundy",
        gold: "gold",
        yellow: "yellow",
        orange: "orange",
        purple: "purple",
        "By signing up, you agree to our ": "By signing up, you agree to our ",
        "Terms and Privacy Policy": "Terms and Privacy Policy",
        "Sign Up": "Sign Up",
        "Sign up": "Sign up",
        "Please enter your password below": "Please enter your password below",
        "Change Email Address": "Change Email Address",
        "Change Phone Number": "Change Phone Number",
        "phone Number": "phone Number",
        "Enter a new password.": "Enter a new password.",
        "Edit your profile information.": "Edit your profile information.",
        "Please enter the code sent to your number":
          "Please enter the code sent to your number",
        "Already have an account?": "Already have an account?",
        "Don't have an account?": "Don't have an account?",
        "Verify Your Number": "Verify Your Number",
        "Please enter the verification code sent to ":
          "Please enter the verification code sent to ",
        "The code you entered is incorrect.Try again":
          "The code you entered is incorrect.Try again",
        "Verify and Continue": "Verify and Continue",
        "Didn't receive a code?": "Didn't receive a code?",
        "Resend code": "Resend code",
        Resend: "Resend",
        in: "in",
        "Wrong Mobile number?": "Wrong Mobile number?",
        "Edit Mobile Number": "Edit Mobile Number",
        "Edit Number": "Edit Number",
        "Confirm New Password": "Confirm New Password",
        "Current Password": "Current Password",
        "Code entered is incorrect. Please try again.":
          "Code entered is incorrect. Please try again.",
        "Scheduled Appointments": "Scheduled Appointments",
        "You will receive a gift once you buy a car as a thank you from us.":
          "You will receive a gift once you buy a car as a thank you from us.",
        "Learn More": "Learn More",
        "You Have No Scheduled Appointments.":
          "You Have No Scheduled Appointments.",
        "You have": "You have",
        "1of": "1 of",
        "appointments to confirm.": "appointments to confirm.",
        "When you book an appoitnment, you’ll find it here.":
          "When you book an appoitnment, you’ll find it here.",
        "Appointments History": "Appointments History",
        "Your gift is waiting for you. Did you buy any of these cars?":
          "Your gift is waiting for you. Did you buy any of these cars?",
        "Notify Us Here!": "Notify Us Here!",
        "You Have No Previous Appointments!":
          "You Have No Previous Appointments!",
        "When you confirm attendance on an appointment, you’ll find it here.":
          "When you confirm attendance on an appointment,  you’ll find it here.",
        "Appointment Date & Time": "Appointment Date & Time",
        "Select your appointment date and time below":
          "Select your appointment date and time below",
        "*Booking an appointment does not prevent the car from being sold to another buyer.":
          "*Booking an appointment does not prevent the car from being sold to another buyer.",
        "Appointment Attendee": "Appointment Attendee",
        "I agree to infrom Markabte team if I buy any car from their platform.":
          "I agree to infrom Markabte team if I buy any car from their platform.",

        Today: "Today",
        Tomorrow: "Tomorrow",
        Sunday: "Sunday",
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        back: "back",
        "Change Mobile Number": "Change Mobile Number",
        Jan: "Jan",
        Feb: "Feb",
        Mar: "Mar",
        Apr: "Apr",
        May: "May",
        Jun: "Jun",
        Jul: "July",
        Aug: "Aug",
        Sep: "Sep",
        Oct: "Oct",
        Nov: "Nov",
        Dec: "Dec",
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
        "appointment successfuly booked": "appointment successfuly booked",
        "data successfuly updated": "data successfuly updated",
        "Manage your appointment and access dealer’s information and location from your garage.":
          "Manage your appointment and access dealer’s information and location from your garage.",
        "Appointment Successfully Booked!": "Appointment Successfully Booked!",
        "Please enter your new number below":
          "Please enter your new number below",
        "Check your personal information below":
          "Check your personal information below",
        rewardDialogText:
          "Because we care, our reward system offers you a variety of discounts and free services upon purchasing a car. We have formed a partnership with various trusted companies, some of them:",
        AM: "AM",
        PM: "PM",
        "Edit Time": "Edit Time",
        "Get Directions": "Get Directions",
        "Dealership Information": "Dealership Information",
        "Appointment Cancelled": "Appointment Cancelled",
        Today: "Today",
        Appointments: "Appointments",
        Appointment: "Appointment",
        arabicFirstSoldAppointment: "",
        "car was sold from your scheduled appoitnemnts.":
          "car was sold from your scheduled appoitnemnts.",
        "cars were sold from your scheduled appoitnemnts.":
          "cars were sold from your scheduled appoitnemnts.",
        "Car Registeration Information": "Car Registeration Information",
        "The car will be registered in the name of":
          "The car will be registered in the name of",
        System: "System",
        Description: "Description",
        "Search for more information": "Search for more information",
        "(3rd Party)": "(3rd Party)",
        "(Markabte)": "(Markabte)",
        Good: "Good",
        Faults: "Faults",
        "Markabte Score": "Markabte Score",
        August: "August",
        "Good/ Good+Issues": "Good/ Good+Issues",
        Issues: "Issues",
        good: "good",
        "No Issues": "No Issues",
        "Report Image": "Report Image",
        "Markabte Inspection": "Markabte Inspection",
        "The Right Way to Inspect!": "The Right Way to Inspect!",
        Our: "Our",
        FREE: "FREE",
        "Inspection Is": "Inspection Is",
        "Our Inspection Categories Are": "Our Inspection Categories Are",
        Comprehensive: "Comprehensive",
        Reliable: "Reliable",
        "User Friendly": "User Friendly",
        "Covers 250+ inspection points of critical areas of the car.":
          "Covers 250+ inspection points of critical areas of the car.",
        "Relies on advanced softwares to ensure unbiased & accurate results.":
          "Relies on advanced softwares to ensure unbiased & accurate results.",
        "Clear terms and scored results.": "Clear terms and scored results.",
        Fundementals: "Fundementals",
        Features: "Features",
        inspectionExterior: "Exterior",
        inspectionInterior: "Interior",
        "Body Paint": "Body Paint",
        "Controls & Switches": "Controls & Switches",
        "Computer Scan": "Computer Scan",
        "AC/Heater": "AC/Heater",
        Tires: "Tires",
        Equipment: "Equipment",
        "All the basics you need to know.": "All the basics you need to know.",
        "Make and Model": "Make and Model",
        "Country Specs": "Country Specs",
        Manufacturer: "Manufacturer",
        "Exterior Color": "Exterior Color",
        "What is different about this car?":
          "What is different about this car?",
        "Alloy Rims": "Alloy Rims",
        "Central Lock": "Central Lock",
        "CD Player": "CD Player",
        Airbags: "Airbags",
        Sunroof: "Sunroof",
        ABS: "ABS",
        "Any visual issue on the outside?": "Any visual issue on the outside?",
        Scratches: "Scratches",
        "Paint Chips": "Paint Chips",
        Dents: "Dents",
        Gouges: "Gouges",
        Rust: "Rust",
        Cracks: "Cracks",
        "Any visual issue on the inside?": "Any visual issue on the inside?",
        Discoloration: "Discoloration",
        Burns: "Burns",
        Holes: "Holes",
        Stains: "Stains",
        "Any re-painted panels?": "Any re-painted panels?",
        Roof: "Roof",
        Fenders: "Fenders",
        Doors: "Doors",
        Hood: "Hood",
        Pillars: "Pillars",
        "Any button, switch, control not working?":
          "Any button, switch, control not working?",
        "Window Controls": "Window Controls",
        "Roof Controls": "Roof Controls",
        "Door Controls": "Door Controls",
        Multimedia: "Multimedia",
        "Seat Controls": "Seat Controls",
        "Any fault codes for mechanical/electrical system?":
          "Any fault codes for mechanical/electrical system?",
        "Airbags Faults": "Airbags Faults",
        "Transmission Faults": "Transmission Faults",
        "Engine Faults": "Engine Faults",
        "Battery Faults": "Battery Faults",
        "ABS Faults": "ABS Faults",
        "We test the AC system too.": "We test the AC system too.",
        "Air Heating": "Air Heating",
        "Air Throw": "Air Throw",
        "Air Cooling": "Air Cooling",
        "Are they safe to drive?": "Are they safe to drive?",
        "Tire’s Brand": "Tire’s Brand",
        "Visual Issue Inspection": "Visual Issue Inspection",
        "Tire’s Age": "Tire’s Age",
        "Remaining Tread Depth": "Remaining Tread Depth",
        "Tire’s Size": "Tire’s Size",
        "Any equipment missing?": "Any equipment missing?",
        "Owner’s Manual": "Owner’s Manual",
        "First Aid Kit": "First Aid Kit",
        "Number of Keys": "Number of Keys",
        Jack: "Jack",
        "Fire Extinguisher": "Fire Extinguisher",
        "Car licenses are kept confidential and are used to validate transactions within our network.":
          "Car licenses are kept confidential and are used to validate transactions within our network.",
        "Last Step!": "Last Step!",
        "Select the car you bought below": "Select the car you bought below",
        "Upload Car License Image": "Upload Car License Image",
        "Upload both sides of the car license below":
          "Upload both sides of the car license below",
        "Upload Front Side Image": "Upload Front Side Image",
        "Upload Back Side Image": "Upload Back Side Image",
        "Thank You for Notifying Us!": "Thank You for Notifying Us!",
        "Our customer service team will contact you as soon as the purchase is confirmed by the dealer.":
          "Our customer service team will contact you as soon as the purchase is confirmed by the dealer.",
        "You Have No Available Cars in Your History.":
          "You Have No Available Cars in Your History.",
        "You can notify purchases on available cars only, or report a problem on a sold car.":
          "You can notify purchases on available cars only, or report a problem on a sold car.",
        "Back to Appointments History": "Back to Appointments History",
        "Inspect the easy way, earn money your way.":
          "Inspect the easy way, earn money your way.",
        "Benefits of being a Markabte officer":
          "Benefits of being a Markabte officer",
        "How to join us?": "How to join us?",
        Requirements: "Requirements",
        "No knowledge required": "No knowledge required",
        "Guided intelligent app for fast & easy inspection.":
          "Guided intelligent app for fast & easy inspection.",
        "Flexible Hours": "Flexible Hours",
        "Select your own working hours based on your suitable time.":
          "Select your own working hours based on your suitable time.",
        "Great Income": "Great Income",
        "Fixed rate on each car inspection, commission on each car sale.":
          "Fixed rate on each car inspection, commission on each car sale.",
        Apply: "Apply",
        "Fill out the form and apply at the top of the page.":
          "Fill out the form and apply at the top of the page.",
        Interview: "Interview",
        "You will be invited for an interview after your application has been considered.":
          "You will be invited for an interview after your application has been considered.",
        Onboarding: "Onboarding",
        "Get your training and be ready to work with us.":
          "Get your training and be ready to work with us.",
        Age: "Age",
        "You have to be over 18 years of age.":
          "You have to be over 18 years of age.",
        Smartphone: "Smartphone",
        "You will need an Android device with internet connection to receive and list the requests.":
          "You will need an Android device with internet connection to receive and list the requests.",
        Car: "Car",
        "You need to have a car with a valid driver’s license.":
          "You need to have a car with a valid driver’s license.",
        Screening: "Screening",
        "The screening process must include issuing a Non-Criminal Certificate.":
          "The screening process must include issuing a Non-Criminal Certificate.",
        "Become an officer now!": "Become an officer now!",
        "Apply Now": "Apply Now",
        "This phone already has a request": "This phone already has a request",
        "Your request has been submitted successfully":
          "Your request has been submitted successfully",
        "Dedicated officers at your fingertips, Qualified buyers to your dealership.":
          "Dedicated officers at your fingertips, Qualified buyers to your dealership.",
        "Benefits of being a Markabte partner":
          "Benefits of being a Markabte partner",
        "Serious Buyers": "Serious Buyers",
        "Reach a higher number of serious buyers to your dealership.":
          "Reach a higher number of serious buyers to your dealership.",
        "Dedicated Workforce": "Dedicated Workforce",
        "Don’t worry about a thing, we handle everything.":
          "Don’t worry about a thing, we handle everything.",
        "Risk-Free": "Risk-Free",
        "No Subscriptions! Pay on successful car sale only.":
          "No Subscriptions! Pay on successful car sale only.",
        Apply: "Apply",
        "Fill out the form and apply at the top of the page.":
          "Fill out the form and apply at the top of the page.",
        "Sign The Contract": "Sign The Contract",
        "Our sales team will visit you after your application has been considered.":
          "Our sales team will visit you after your application has been considered.",
        "Sell Cars!": "Sell Cars!",
        "Meet our serious buyers at your dealership and close the deal.":
          "Meet our serious buyers at your dealership and close the deal.",
        "Join Us for Free!": "Join Us for Free!",
        DealershipName: "Dealership Name",
        "Join Now": "Join Now",
        city: "city",
        Terms: "Terms",
        and: "and",
        "join now": "join now",
        Amman: "Amman",
        Aqaba: "Aqaba",
        Jerash: "Jerash",
        Irbid: "Irbid",
        Madaba: "Madaba",
        Zarqa: "Zarqa",
        Kerak: "Kerak",
        "al-mafraq": "al-mafraq",
        "Al-Mafraq": "Al-Mafraq",
        "As-Salt": "As-Salt",
        Ajloun: "Ajloun",
        "as-salt": "as-salt",
        "ma'an": "ma'an",
        "at-tafilah": "At-tafilah",
        "Mobile number already taken.": "Mobile number already taken.",
        "Email already taken.": "Email already taken.",
        "Thank you for applying to us!": "Thank you for applying to us!",
        "Our team will contact you as soon as possible.":
          "Our team will contact you as soon as possible.",
        "Go to Homepage": "Go to Homepage",
        "Coming soon": "Coming soon",
        "We are currently working on it!": "We are currently working on it!",
        "Oops!": "Oops!",
        "Something went wrong, please try again later.":
          "Something went wrong, please try again later.",
        "Copy Link": "Copy Link",
        "Share Car Listing": "Share Car Listing",
        copied: "copied",
        " yr. ": " yr. ",
        " mos": " mos",
        closed: "closed",
        Poor: "Poor",
        "View Image": "View Image",
        Moderate: "Moderate",
        "A measure that reflects the general condition of battery & it’s ability to deliver needed performance ccompared to  new battery.":
          "A measure that reflects the general condition of battery & it’s ability to deliver needed performance ccompared to  new battery.",
        "We estimate the battery’s remaining life by comparing its current capacity to its ideal capacity when new":
          "We estimate the battery’s remaining life by comparing its current capacity to its ideal capacity when new",
        "Faults | ": "Faults | ",
        Check: "Check",
        "Markabte Search for Pre-inspected cars for free  on Markabte markabte.com":
          "Markabte Search for Pre-inspected cars for free  on Markabte markabte.com",
        Previous: "Previous",
        "Click to view issues": "Click to view issues",
        "* We do not record any issue in the body that is less than 0.2 (cm).":
          "* We do not record any issue in the body that is less than 0.2 (cm).",
        "* In some cases, Re-painted panels cannot be detected.":
          "* In some cases, Re-painted panels cannot be detected.",
        "* We do not record any scratch that cannot be felt by hand.":
          "* We do not record any scratch that cannot be felt by hand.",
        "* We do not inspect whether the battery was replaced or repaired.":
          "* We do not inspect whether the battery was replaced or repaired.",
        "* We do not check if the battery was replaced or repaired.":
          "* We do not check if the battery was replaced or repaired.",
        "* We do not inspect the inside of the tire or the internal sidewall of the tire.":
          "* We do not inspect the inside of the tire or the internal sidewall of the tire.",
        "* We do not confirm the genuineness of the equipment.":
          "* We do not confirm the genuineness of the equipment.",
        "* We dont record fault codes that are saved in the car’s computer but are inactive (Repaired).":
          "* We dont record fault codes that are saved in the car’s computer but are inactive (Repaired).",
        "* We do not validate the results of an inspection report from any third party.":
          "* We do not validate the results of an inspection report from any third party.",
        "No Notes": "No Notes",
        Notes: "Notes",
        "Replace Battery": "Replace Battery",
        "Not Inspected due to influence of resistance values":
          "Not Inspected due to influence of resistance values",
        "Unlock Info (1 Point)": "Unlock Info (1 Point)",
        "Inspected Point": "Inspected Point",
        "Inspection scores are locked.": "Inspection scores are locked.",
        Points: "Points",
        "Inspection Details are Locked.": "Inspection Details are Locked.",
        "Check out a sample of our inspection report for different cars.":
          "Check out a sample of our inspection report for different cars.",
        "Report Samples>": "Report Samples>",
        "What We Inspect?": "What We Inspect?",
        "Want to know more?": "Want to know more?",
        "Go to inspection process >": "Go to inspection process >",
        "See For Yourself!": "See For Yourself!",
        "Check out a sample of our inspection":
          "Check out a sample of our inspection",
        "report for different cars.": "report for different cars.",
        "Report Samples>": "Report Samples>",
        "Check out a sample of our inspection report":
          "Check out a sample of our inspection report",
        "for different cars.": "for different cars.",
        "How It Works?": "How It Works?",
        "Inspection Centers": "Inspection Centers",
        "Car Websites": "Car Websites",
        "Pre-Inspected Vehicles": "Pre-Inspected Vehicles",
        "All of Markabte’s cars are inspected by professionals.":
          "All of Markabte’s cars are inspected by professionals.",
        "Online Detailed Inspection Report":
          "Online Detailed Inspection Report",
        "Our inspection is fully digitized, avaialble upfront.":
          "Our inspection is fully digitized, avaialble upfront.",
        "Trust and Transparency": "Trust and Transparency",
        "All of Markabte’s cars are inspected by professionals.":
          "All of Markabte’s cars are inspected by professionals.",
        "Touring Dealership": "Touring Dealership",
        "No need to waste time touring, browse variety of cars.":
          "No need to waste time touring, browse variety of cars.",
        "Dedicated Advisor": "Dedicated Advisor",
        "Markabte Advisor will be at your service anytime.":
          "Markabte Advisor will be at your service anytime.",
        Markabte: "Markabte",
        ////////////////////////
        "Text Seller": "Text Seller",
        "Call Us": "Call Us",
        "Call Seller": "Call Seller",
        "Chat With Us": "Chat With Us",
        "Are you sure you want to request history report for":
          "Are you sure you want to request history report for",
        "Select the car you want": "Select the car you want",
        "Request Carseer History Report": "Request Carseer History Report",
        "Request History Report": "Request History Report",
        "1 Carseer History Report": "1 Carseer History Report",
        "Markabte Advisor": "Markabte Advisor",
        Car: "Car",
        Cars: "Cars",
        Credits: "Credits",
        Subscribe: "Subscribe",
        Upgrade: "Upgrade",
        advisor: "advisor",
        "Get in Touch": "Get in Touch",
        "Request Report": "Request Report",
        "My Cars": "My Cars",
        "Markabte Advisor Requested": "Markabte Advisor Requested",
        "Markabte Advisor will contact you soon.":
          "Markabte Advisor will contact you soon.",
        "Wrong Number": "Wrong Number",
        "Update Number": "Update Number",
        "History Report Requested": "History Report Requested",
        "Our customer support team will contact you soon.":
          "Our customer support team will contact you soon.",
        Exp: "Exp",
        days: "days",
        months: "months",
        expired: "expired",
        "Markabte Inspection Sample Report":
          "Markabte Inspection Sample Report",
        "Select a car to view full inspection report":
          "Select a car to view full inspection report",
        "No Additional Services": "No Additional Services",
        "Car Credits": "Car Credits",
        "Remaining balance after use": "Remaining balance after use",
        Plan: "Plan",
        "Subscription Plans": "Subscription Plans",
        "*As low as": "*As low as",
        "Choose a method that fits your needs":
          "Choose a method that fits your needs",
        ContinueCharge: "Continue",
        "*Only for": "*Only for",
        "/Car": "Car",
        "Charge Car Credits": "Charge Car Credits",
        "Unlock cars and access valuable benefits.":
          "Unlock cars and access valuable benefits.",
        "Are you sure you want to unlock inspection details and seller’s information for this car?":
          "Are you sure you want to unlock inspection details and seller’s information for this car?",
        "Interested in This Car?": "Interested in This Car?",
        "Use your Credits to unlock inspection report and more.":
          "Use your Credits to unlock inspection report and more.",
        "Unlock Car": "Unlock Car",
        "Charge Credits": "Charge Credits",
        Unlocked: "Unlocked",
        "Go to Sample Reports": "Go to Sample Reports",
        "How We Compare?": "How We Compare?",
        "Detailed and comprehensive inspection report available with every car":
          "Detailed and comprehensive inspection report available with every car",
        "High Quality Content": "High Quality Content",
        "Standardized car information without fake or duplicate listings":
          "Standardized car information without fake or duplicate listings",
        "Unbiased Information": "Unbiased Information",
        "All details are not posted by the seller":
          "All details are not posted by the seller",
        "Advisor available to provide any assistance or answer any questions in your journey to purchase a car":
          "Advisor available to provide any assistance or answer any questions in your journey to purchase a car",
        "No Ads": "No Ads",
        "No irrelevant ads, just cars": "No irrelevant ads, just cars",
        "Best Prices": "Best Prices",
        "Pay less & get more": "Pay less & get more",
        "Available Upfront & Online": "Available Upfront & Online",
        "Know the car’s condition instantly & from your home":
          "Know the car’s condition instantly & from your home",
        "Inspection Automation Policy": "Inspection Automation Policy",
        "Advanced inspection technology and processes to reduce human interference and human error":
          "Advanced inspection technology and processes to reduce human interference and human error",
        "Visual Condition Report": "Visual Condition Report",
        "Interactive visual report of cosmetic damages from the car’s exterior and interior":
          "Interactive visual report of cosmetic damages from the car’s exterior and interior",
        "Buyer Friendly Report": "Buyer Friendly Report",
        "Clear terms and scored results organized in an easy format":
          "Clear terms and scored results organized in an easy format",
        "Find Your Suitable Car": "Find Your Suitable Car",
        "Browse different cars and find the one that fits your needs.":
          "Browse different cars and find the one that fits your needs.",
        "Unlock Inspection Details": "Unlock Inspection Details",
        "Subscribe and know the true car condition upfront.":
          "Subscribe and know the true car condition upfront.",
        "Contact Seller & Buy Your Car": "Contact Seller & Buy Your Car",
        "Meet the seller and seal the deal.":
          "Meet the seller and seal the deal.",
        "One Time Purchase": "One Time Purchase",
        "Plan upgraded successfully.": "Plan upgraded successfully.",
        "Upgrade Plan": "Upgrade Plan",
        "Choose the number of cars below": "Choose the number of cars below",
        "Re-fill Credits": "Re-fill Credits",
        "Unlock one car, no strings attached.":
          "Unlock one car, no strings attached.",
        "Re-fill at same price": "Re-fill at same price",
        "Markabte Subscription Plans": "Markabte Subscription Plans",
        "Choose subscription plan that fits your needs.":
          "Choose subscription plan that fits your needs.",
        "You can access seller’s informtion and location from your garage.":
          "You can access seller’s informtion and location from your garage.",
        "Seller’s Information": "Seller’s Information",
        JDS: "JDS",
        "Per car": "Per car",
        "Plan credits depleted? Re-fill your credits at the lowest price of":
          "Plan credits depleted? Re-fill your credits at the lowest price of",
        "Continue to Payment": "Continue to Payment",
        "Total Subscription Price": "Total Subscription Price",
        "Still unsure? Get 1 free Carseer history report of a car of your choice.":
          "Still unsure? Get 1 free Carseer history report of a car of your choice.",
        "1 Free History Report": "1 Free History Report",
        "Feeling lost? Our advisors will help you out with anything.":
          "Feeling lost? Our advisors will help you out with anything.",
        "JD/Car": "JD/Car",
        "Price/Car": "Price/Car",
        "plan successfully!": "plan successfully!",
        "Car Unlocked Successfully!": "Car Unlocked Successfully!",
        "Unlocked Cars": "Unlocked Cars",
        "1 car credit has been added to your balance.":
          "1 car credit has been added to your balance.",
        "Inspection Details are Locked.": "Inspection Details are Locked.",
        "Check out a sample of our inspection report for different cars.":
          "Check out a sample of our inspection report for different cars.",
        "View Report Samples": "View Report Samples",
        "Subscribed to": "Subscribed to",
        Benefits: "Benefits",
        Continue: "Continue",
        "Number of Cars": "Number of Cars",
        // Unlocked: "Unlocked",
        "Inspection Unlocked": "Inspection Unlocked",
        "Not enough credits": "Not enough credits",
        "Account Created Successfully!": "Account Created Successfully!",
        "You have 1 free point in your balance to access any car of your choice.":
          "You have 1 free point in your balance to access any car of your choice.",
        Continue: "Continue",
        "Re-painted": "Re-painted",
        "Original Paint": "Original Paint",
        "Not measured": "Not measured",
        "Limited Offer": "Limited Offer",
        "Free Inspection Credit for New Users.":
          "Free Inspection Credit for New Users.",
        "Sign Up Now!": "Sign Up Now!",
        "Free Inspection Credit": "Free Inspection Credit",
        "for New Users.": "for New Users.",
        "You are Now a Markabte User!": "You are Now a Markabte User!",
        "Use your free credit to unlock any car inspection report of your choice.":
          "Use your free credit to unlock any car inspection report of your choice.",
        "Free Plan": "Free Plan",
        "Car Views": "Car Views",
        "Click on the views to check the car's condition from a specific view.":
          "Click on the views to check the car's condition from a specific view.",
        "You can view all the issues of the selected car view here.":
          "You can view all the issues of the selected car view here.",
        "issues list": "issues list",
        "Click on the hotspots to see an up-close image of the damage.":
          "Click on the hotspots to see an up-close image of the damage.",
        "Damage Areas": "Damage Areas",
        Next: "Next",
        "Click here for help": "Click here for help",
        ////////////////////
        "Terms & Conditions": "Terms & Conditions",
        "Acceptance of Terms & Conditions": "Acceptance of Terms & Conditions",
        AcceptanceFirstParagraphFirstHalf:
          "The following terms and conditions govern all use of the",
        AcceptanceFirstParagraphSecondtHalf:
          "website and all content, services and products available at or through the website. The Website (“Markabte.com”) is owned and operated by Advanced Markabte for Digital Marketing LL.C. (“Markabte”). The Website is offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies and procedures that may be published from time to time on this website by Markabte (collectively referred to “Agreement”).",
        AcceptanceSecondParagraph:
          "You are advised to carefully read the Agreement prior accessing or using the Website or any of its features. By accessing or using any part of the website, you accept that this agreement is legally valid and binding.  Should you not accept any of the Terms or Conditions contained herein this agreement, then you shall immediately leave the Website. If these terms and conditions are considered an offer by Markabte, acceptance is expressly limited to these terms.",
        "I. Age restriction": "I. Age restriction",
        AgeParagraph:
          "You must be at least 18 (eighteen) years of age or reach the age of majority in the country of your residence prior your use to the website. By using this website, you warrant that you are at least 18 years of age and you may legally adhere to this Agreement. Markabte assumes no responsibility for liabilities related to age misrepresentation.",
        "II. User Account": "II. User Account",
        UserAccountFirstParagraph:
          "If you create a user account to access the services of this Website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account including but not limited to the content you add to your account on the Website and any unlawful or illegal activities carried using your account. ",
        UserAccountSecondParagraph:
          "Therefore, you understand and accept not to share your user account or password with any person or a third-party, where you will solely be liable for any harm or damages resulting from any illegal, unlawful and harmful acts carried by your user account during your activity on the Website. ",
        UserAccountThirdParagraph:
          "If you become aware or suspect that there are any possible issues regarding the security of your account on the website, you shall immediately inform us and change your password. ",
        UserAccountFourthParagraph:
          "We reserve all rights to terminate accounts, edit or remove content at our sole discretion.",
        "III. Responsibility of Users": "III. Responsibility of Users",
        UserResponsibilityParagraph:
          "If you post material to the Website, post links on the Website, or otherwise make (or allow any third party to make) material available by means of the Website (any such material, “Content”), You are entirely responsible for the content of and any harm resulting from that Content regardless whether the Content consists of text, graphics, audio, or software. By making Content available, you represent and warrant that:",
        UserResponsibilityFirstListItem:
          "the downloading, copying and use of the Content will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark or trade secret rights, of any third party.",
        UserResponsibilitySecondListItem:
          "you have fully complied with any third-party licenses relating to the Content, and took all necessary measures to satisfy the terms and conditions of the third party.",
        UserResponsibilityThirdListItem:
          "the Content does not contain or install any viruses, worms, malware, Trojan horses or other harmful or destructive content;",
        UserResponsibilityFourthListItem:
          "the Content is not spam, is not machine- or randomly-generated, and does not contain unethical or unwanted commercial content designed to drive traffic to third party sites or boost the search engine rankings of third party sites, or to further unlawful acts (such as phishing);",
        UserResponsibilityFifthListItem:
          "the Content shall not conflict with the Taboo of Country of Operation or include any Pornographic and/or Nudity or any Inappropriate or Illegal content.",
        UserResponsibilitySixthListItem:
          "By submitting Content to Markabte for inclusion on your account, you grant Markabte a world-wide, royalty-free, and non-exclusive license to reproduce, modify, adapt and publish the Content solely for the purpose of displaying, distributing and promoting your account. If you delete Content, Markabte will use reasonable efforts to remove it from the Website, but you acknowledge that caching or references to the Content may not be made immediately unavailable.",
        UserResponsibilitySeventhListItem:
          "Without limiting any of those representations or warranties, Markabte has the right at its sole discretion to refuse or remove any content that, in Markabte’s reasonable opinion, violates any Markabte policy or is in any way harmful.",
        "IV. Payment": "IV. Payment",
        "V. Restricted Use": "V. Restricted Use",
        "VI. Responsibility for Using the Website":
          "VI. Responsibility for Using the Website",
        "VII. Copyright": "VII. Copyright",
        "VIII. Intellectual Property": "VIII. Intellectual Property",
        "IX. Advertisements": "IX. Advertisements",
        "X. Alterations": "X. Alterations",
        "XI. Termination": "XI. Termination",
        "XII. Cancelation & Expiration": "XII. Cancelation & Expiration",
        "XIII. Refund": "XIII. Refund",
        "XIV. Indemnification": "XIV. Indemnification",
        PaymentFirstParagraph:
          "By subscribing to any plans on the Website you agree to processing your payment through a third-party payment gateway. Processing your payment may be subject to additional fees for currency conversion rates or any other fees imposed by your bank.",
        PaymentSecondParagraph:
          "You will be entitled to purchase the following through our website:",
        PaymentFirstListItem:
          "Car Services: To receive a technical support and guidance by Expertise Markabte Team.",
        PaymentSecondListItem:
          "Car Credits: To unlock any inspection report(s).",
        RestUseParagraph:
          "Markbate may terminate or deny your access to use the Website for any reason without a prior notice in the event of violating any of the policies or breaching any of the terms of this Agreement. Should your access to the website is revoked you will not be entitled to refund any amounts paid under your account for purchasing any plans.",
        RespUseParagraph:
          " You are responsible for taking precautions as necessary to protect yourself and your device from viruses, worms, Trojan horses, and other harmful or destructive content. The Website may contain some technical inaccuracies, typographical mistakes, and other errors. Markabte disclaims any liability for any harm resulting from the use of the Website, or from any content you may download.",
        CopyRightParagraph:
          " Markabte will terminate your access to use the Website if, under appropriate circumstances, it is determined that you have infringed the copyrights or any other intellectual property rights of Markabte or any third party.",
        IntPropFirstListItem:
          "You agree that all materials, products, and services provided on this website are the property of Markabte, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the Markabte’s intellectual property in any way, including electronic, digital, or new trademark registrations.",
        IntPropSecondListItem:
          "You grant Markabte a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish.",
        AdvParagraph:
          "Markabte reserves the right to display advertisements on your profile on the Website.",
        AltParagraph:
          "Markabte reserves the right, at its sole discretion, to modify or replace any part of this Agreement. It is your responsibility to check this Agreement periodically for changes. Your continued use of or access to the Website following the posting of any changes to this Agreement constitutes acceptance of those changes. Markabte may also, in the future, offer new services and/or features through the Website (including, the release of new tools and products) where such new features and/or services shall be subject to the terms and conditions of this Agreement.",
        TerminationParagraph:
          "Markabte may terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately. If you wish to terminate this Agreement or your Markabte.com account, you may simply discontinue using the Website. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.",
        CancellationParagraph:
          "This Website does not permit any payment cancelation for any plan purchase that has been completed.  Any purchased plans including both car credits & services will expire 90 days from the purchase date regardless if it has been used or not.",
        RefundFirstParagraph:
          "Once you purchase any plan you cannot refund any payment that you have already made. Notwithstanding the aforementioned, Markbate will refund your credits to display the inspection report in the event the report of any vehicle has been unlocked and it is sold within the following 24 hours from the time the report was unlocked.",
        RefundSecondParagraph:
          "Markabte will not at any time make any monetary refund and the refund stated above will be exclusively for Credit Refund.",
        IdemnificationParagraph:
          "You agree to indemnify and hold Markabte harmless, its contractors, and its licensors, and their respective directors, officers, employees and agents from and against any and all claims and expenses, including attorneys’ fees, arising out or connected to your use of the Website, including but not limited to your violation and breach to the terms of this Agreement.",

        "Click here to view the issue’s list of a certain view.":
          "Click here to view the issue’s list of a certain view.",
        "Inspection Unavailable, diagnostic tools could not connect to car's computer":
          "Inspection Unavailable, diagnostic tools could not connect to car's computer",
        "Driving cycle inadequate, car must be driven for 70km and re-inspected for reliable results":
          "Driving cycle inadequate, car must be driven for 70km and re-inspected for reliable results",
        "Voltage difference inconclusive, due to the effect of Abnormal resistance values":
          "Voltage difference inconclusive, due to the effect of Abnormal resistance values",
        "for sale": "for sale",
        "Max Price": "Max Price",
        "Min Price": "Min Price",

        "Card Information": "Card Information",
        "Name on Card*": "Name on Card*",
        "Card Number*": "Card Number*",
        "Expiry Date*": "Expiry Date*",
        "You can find this 3-digit code at the back of your card.":
          "You can find this 3-digit code at the back of your card.",
        "CVV/CVC*": "CVV/CVC*",
        "*Card information are not stored in our databases.":
          "*Card information are not stored in our databases.",
        "Total Subscription Fees": "Total Subscription Fees",
        "*Subscribtion valid for": "*Subscribtion valid for",
        Pay: "Pay",
        "Are you sure you want to unlock inspection details and seller’s information for this car?":
          "Are you sure you want to unlock inspection details and seller’s information for this car?",
        "Unlock Inspection": "Unlock Inspection",
        "Remaining balance after use": "Remaining balance after use",
        "I'm Interested": "I'm Interested",
        "Unlock inspection report, connect with seller and much more.":
          "Unlock inspection report, connect with seller and much more.",
        "Connect with seller and much more.":
          "Connect with seller and much more.",
        "Call Seller Anonymously": "Call Seller Anonymously",
        "Current Balance": "Current Balance",
        "Choose your desired action from below":
          "Choose your desired action from below",
        "What do you want to do?": "What do you want to do?",
        "Limited Human Intervention": "Limited Human Intervention",
        "Pay Less, Get More": "Pay Less, Get More",

        "Payment Summary": "Payment Summary",
        "*Car Credit valid for": "*Car Credit valid for",
        "Discount Code": "Discount Code",
        "Enter Discount Code": "Enter Discount Code",
        "Plan Type": "Plan Type",
        "Price per car": "Price per car",
        "Discount Amount": "Discount Amount",
        "Total Amount": "Total Amount",
        "Payment Method": "Payment Method",
        carsPaymentMethod: "cars",
        "We are working hard to launch our service on Tuesday 31, May.":
          "We are working hard to launch our service on Tuesday 31, May.",
        "Until then, you can explore the beta version of our website.":
          "Until then, you can explore the beta version of our website.",
        "Explore Beta Version": "Explore Beta Version",
        "Under Construction": "Under Construction",
        "*Please make sure you have  an available mobile signal.":
          "*Please make sure you have  an available mobile signal.",
        "You will be connected with the seller anonymously via Markabte Call Portal.":
          "You will be connected with the seller anonymously via Markabte Call Portal.",
        "Call Me Now": "Call Me Now",
        "Unlock inspection report and other services with one of our subcription plans.":
          "Unlock inspection report and other services with one of our subcription plans.",
        "Sorry, you can't call the seller past working hours. Please call during working hours from":
          "Sorry, you can't call the seller past working hours. Please call during working hours from",
        "How it works - Three easy steps": "How it works - Three easy steps",
        Details: "Details",
        "Car Loading ...": "Car Loading ...",
        "No Notes": "No Notes",
        Notes: "Notes",
        "Expiry Year*": "Expiry Year*",
        "Expiry Month*": "Expiry Month*",
        "Total Price": "Total Price",
        "Car Credits Pricings": "Car Credits Pricings",
        "Choose the pricing that fits your needs":
          "Choose the pricing that fits your needs",
        "One car and that’s it. Nothing more, nothing less.":
          "One car and that’s it. Nothing more, nothing less.",
        "Two cars in mind? That’s fine. Unlock them both.":
          "Two cars in mind? That’s fine. Unlock them both.",
        "Want the best car? Unlock all your options.":
          "Want the best car? Unlock all your options.",
        "Package Type": "Package Type",
        "Total Package Fees": "Total Package Fees",
        "*Car Credits valid for 3 months": "*Car Credits valid for 3 months",
        "Frequently Asked Questions": "Frequently Asked Questions",
        "View our FAQ about Markabte’s inspection below or contact us for any question.":
          "View our FAQ about Markabte’s inspection below or contact us for any question.",
        "Markabte’s Inspection FAQ": "Markabte’s Inspection FAQ",
        "Frequently Asked Questions": "Frequently Asked Questions",
        "Have more questions?": "Have more questions?",
        "If you cannot find the answer to your question, contact us on":
          "If you cannot find the answer to your question, contact us on",
        "We are at your service.": "We are at your service.",
        "Sorry, the seller is not accepting calls for today. Please try again on another day.":
          "Sorry, the seller is not accepting calls for today. Please try again on another day.",
        "No Cars in Your Garage!": "No Cars in Your Garage!",
        "Browse and unlock cars in your garage.":
          "Browse and unlock cars in your garage.",
        " yr. ": " yr. ",
        " mos": " mos",
        "* Visual report includes scratches with (Deep) severity only NOT with (Light) severity":
          "* Visual report includes scratches with (Deep) severity only NOT with (Light) severity",
        "* We do not take images of light scratches on the car's Exterior":
          "* We do not take images of light scratches on the car's Exterior",
        "Not Included": "Not Included",
        "We scan the vehicle's on-board computer to diagnose & identify fault codes within the hybrid Battery system.":
          "We scan the vehicle's on-board computer to diagnose & identify fault codes within the hybrid Battery system.",
        "Contact Markabte Agent": "Contact Markabte Agent",
        "Choose your desired action from below":
          "Choose your desired action from below",
        "Call Markabte Agent": "Call Markabte Agent",
        "Text Markabte Agent via WhatsApp": "Text Markabte Agent via WhatsApp",
        "Request Call Back": "Request Call Back",
        "Text Markabte Agent": "Text Markabte Agent",
        System: "System",
        "This Account belongs to the Dealer/ Officer network, please create a new User account with a different phone number.":
          "This Account belongs to the Dealer/ Officer network, please create a new User account with a different phone number.",
        "Phone Number or email already have account associated. Kindly try forgot password":
          "Phone Number or email already have account associated. Kindly try forgot password",
        Inspected: "Inspected",
        "Hybrid Battery": "Hybrid Battery",
        "(Lithium)": "(Lithium)",
        "Request Call Back": "Request Call Back",
        "Text Agent": "Text Agent",
        "Seller Information": "Seller Information",
        "Indepedent Seller": "Indepedent Seller",
        "markabte agent will call you shortly.":
          "markabte agent will call you shortly.",
        "I am interested in": "I am interested in",
        "with listing id": "with listing id",
        "Tell Us About Your Car": "Tell Us About Your Car",
        "Set Appointment for Inspection": "Set Appointment for Inspection",
        "Advertising and Receiving Buyers": "Advertising and Receiving Buyers",
        "Get The Best Sell Offer": "Get The Best Sell Offer",
        "Sell Your Car With Markabte": "Sell Your Car With Markabte",
        "Best Price": "Best Price",
        "Fast Sell": "Fast Sell",
        "Risk Free": "Risk Free",
        "Easy & Convienent": "Easy & Convienent",
        "The New Way to Sell Your Car.": "The New Way to Sell Your Car.",
        "Enter your car’s information and set your price or we will help you with valuation.":
          "Enter your car’s information and set your price or we will help you with valuation.",
        "We will send an inspection officer at the time and place suitable for you.":
          "We will send an inspection officer at the time and place suitable for you.",
        "We will assign a specialist to answer all phone calls and negotiate with buyers.":
          "We will assign a specialist to answer all phone calls and negotiate with buyers.",
        "We coordinate with the buyer and help you with ownership transfer papers.":
          "We coordinate with the buyer and help you with ownership transfer papers.",
        "Loading, Please wait.": "Loading, Please wait.",
        "Contact Sales Agent": "Contact Sales Agent",
        Call: "Call",
        "Call Sales Agent": "Call Sales Agent",
        "Text Sales Agent via WhatsApp": "Text Sales Agent via WhatsApp",
        "Text Sales Agent": "Text Sales Agent",
        "Do you have any questions?": "Do you have any questions?",
        "Contact your dedicated sales agent":
          "Contact your dedicated sales agent",
        "Contact Sales Agent": "Contact Sales Agent",
        "Your are not signed in": "Your are not signed in",
        "You must be signed in to view the Seller inspection report":
          "You must be signed in to view the Seller inspection report",
        "Selling Process": "Selling Process",
        "Buying Process": "Buying Process",
        "Find Your Suitable Car": "Find Your Suitable Car",
        "Benefits of Using Markabte": "Benefits of Using Markabte",
        "Highest Saving & Best Service!": "Highest Saving & Best Service!",
        "Choose the package that best suits your needs.":
          "Choose the package that best suits your needs.",
        "Charge Your Credit Balance": "Charge Your Credit Balance",
        "Choose the package that best fits your needs.":
          "Choose the package that best fits your needs.",
        "Tell Us About Your Car": "Tell Us About Your Car",
        "Enter your car’s information and set your price or we will help you with valuation.":
          "Enter your car’s information and set your price or we will help you with valuation.",
        "Set Appointment for Inspection": "Set Appointment for Inspection",
        "We will send an inspection officer at the time and place suitable for you.":
          "We will send an inspection officer at the time and place suitable for you.",
        "We Receive Calls From Buyers": "We Receive Calls From Buyers",
        "We will assign a specialist to answer all phone calls and negotiate with buyers.":
          "We will assign a specialist to answer all phone calls and negotiate with buyers.",
        "Get The Best Sell Offer": "Get The Best Sell Offer",
        "We coordinate with the buyer and help you with ownership transfer papers.":
          "We coordinate with the buyer and help you with ownership transfer papers.",
        "The Best Way to": "The Best Way to",
        Cars: "Cars",
        Sell: "Sell",
        Buy: "Buy",
        and: "and",
        "Search for a Car": "Search for a Car",
        "Sell Your Car": "Sell Your Car",
        "Inspection Sample Reports >": "Inspection Sample Reports >",
        "25 JD": "25 JD",
        "Starting From": "Starting From",
        "Per Car": "Per Car",

        "Car Information": "Car Information",
        "Basic Car Details": "Basic Car Details",
        "Personal Information": "Personal Information",
        "Personal Details": "Personal Details",
        "Sell My Car Request": "Sell My Car Request",
        "Edit Information": "Edit Information",
        "Request Call Back": "Request Call Back",
        Continue: "Continue",
        "Yes (Please attach car frame image)":
          "Yes (Please attach car frame image)",
        No: "No",
        "Upload Image": "Upload Image",
        "How much do you want to sell your car for?":
          "How much do you want to sell your car for?",
        "Mileage*": "Mileage*",
        "Unit of Measurement*": "Unit of Measurement*",
        "City*": "City*",
        "Area*": "Area*",
        "Full Name*": "Full Name*",
        "Year*": "Year*",
        "Did you get your car frame inspected?":
          "Did you get your car frame inspected?",
        "Car Price": "Car Price",
        "I do not know": "I do not know",
        No: "No",
        "Ex: Khalda": "Ex: Khalda",
        "Car Frame": "Car Frame",
        "Do you want to sell your car?": "Do you want to sell your car?",
        "Markabte will handle the matter.": "Markabte will handle the matter.",
        "Sell Your Car With Markabte": "Sell Your Car With Markabte",
        "Best Price": "Best Price",
        "Set your price and receive offers from buyers.":
          "Set your price and receive offers from buyers.",
        "Fast Sell": "Fast Sell",
        "We inspect and advertise your car to reach the largest number of buyers.":
          "We inspect and advertise your car to reach the largest number of buyers.",
        "Risk Free": "Risk Free",
        "You will only pay on successful car sale only.":
          "You will only pay on successful car sale only.",
        "Easy & Convienent": "Easy & Convienent",
        "Keep your car and a specialist will take care of selling it on your behalf.":
          "Keep your car and a specialist will take care of selling it on your behalf.",
        "We will call you back ٍsoon.": "We will call you back ٍsoon.",
        "Loading, Please wait.": "Loading, Please wait.",
        "Contact Sales Agent": "Contact Sales Agent",
        Call: "Call",
        "Call Sales Agent": "Call Sales Agent",
        "Text Sales Agent via WhatsApp": "Text Sales Agent via WhatsApp",
        "Text Sales Agent": "Text Sales Agent",
        "Do you have any questions?": "Do you have any questions?",
        "Contact your dedicated sales agent":
          "Contact your dedicated sales agent",
        "Contact Sales Agent": "Contact Sales Agent",
        "Your are not signed in": "Your are not signed in",
        "You must be signed in to view the Seller inspection report":
          "You must be signed in to view the Seller inspection report",
        "Not Specified": "Not Specified",
        "Not valid": "Not valid",
        Unlock: "Unlock",
        "Contact Markabte Agent directly.": "Contact Markabte Agent directly.",
        "Contact the seller directly.": "Contact the seller directly.",
        "Unlock inspection report and contact the seller.":
          "Unlock inspection report and contact the seller.",
        "Choose method of contact below": "Choose method of contact below:",
        "Please charge your balance to unlock inspection reports.":
          "Please charge your balance to unlock inspection reports.",
        "Call me": "Call me",
        "Send Whatsapp Text": "Send Whatsapp Text",
        "Charge Credit": "Charge Credit",
        "Please wait, we will call you now.":
          "Please wait, we will call you now.",
        "Similar Cars": "Similar Cars",
        "Recently Listed Cars": "Recently Listed Cars",
        applyButton: "Apply",
        "All filters are Cleared.": "All filters are Cleared.",
        City: "City",
        Area: "Area",
        Make: "Make",
        Model: "Model",
        Year: "Year",
        Date: "Date",
        Time: "Time",
        "Buyer Name": "Buyer Name",
        "Buyer Phone Number": "Buyer Phone Number",
        "Seller Name": "Seller Name",
        "Seller Phone Number": "Seller Phone Number",
        "Book now!": "Book now!",
        "Any Questions?": "Any Questions?",
        "Contact our technical support specialist":
          "Contact our technical support specialist",
        "Call back request": "Call back request",
        "The technical support specialist will contact you as soon as possible.":
          "The technical support specialist will contact you as soon as possible.",
        "Phone number": "Phone number",
        Approve: "Approve",
        "Appointment booked successfully.": "تم حجز الموعد بنجاح.",
        "Something went wrong, please try again.":
          "حدث خطأ, يرجى المحاولة مرة اخرىز",
      },
    },
    ar: {
      translations: {
        "1 of": "1 من",
        "Car Listings": "قائمة السيارات",
        Earnings: "الأرباح",
        "Listing Request": "طلبات الإدراج",
        Language: "اللغة",
        "Sign out": "تسجيل الخروج",
        Logout: "تسجيل الخروج",
        Dispatcher: "المرسل",
        Officer: "موظف",
        "Car Details": "تفاصيل السيارة",
        "Edit Car": "تحرير السيارة",
        "Add New Car": "إضافة سيارة جديدة",
        "Edit Car Information": "تحرير معلومات السيارة",
        "Submit to qc": " إرسال لمراقبة الجودة ",
        "Submit Form": "تقديم النموذج",
        "Submit & Exit": "إرسال وخروج",
        Exit: "الخروج",
        Submit: "إرسال",
        "email already used": "الأيميل مستخدم بالفعل",
        "Sort By": "ترتيب حسب",
        Sort: "ترتيب",
        "Date (Oldest First)": "تاريخ الإضافة (الأقدم أولاً)",
        "Date (Newest First)": "تاريخ الإضافة (الأحدث أولاً)",
        "Price (High to Low)": "السعر (من الاعلى الى الأقل)",
        "Price (Low to High)": "السعر (من الأقل الى الاعلى)",
        "Score (High to Low)": "نتيجة الفحص (من الأعلى إلى الأقل)",
        "Score (Low to High)": "نتيجة الفحص (من الأقل إلى الأعلى)",
        "Odometer (Low to High)": "الكيلومترات (من الأقل إلى الأعلى)",
        "Odometer (High to Low)": "الكيلومترات (من الأعلى إلى الأقل)",
        "Year (Oldest First)": "السنة (الأقدم أولاً)",
        "Year (Newest First)": "السنة (الأحدث أولاً)",
        Date: "التاريخ",
        DateFilter: "تاريخ الإضافة",
        Price: "السعر",
        Score: "النتيجة",
        ScoreFilter: "نتيجة الفحص",
        Milage: "العداد",
        Year: "السنة",
        To: "إلى",
        Average: "معدل",
        "or less": "او أقل",
        "or more": "او أكثر",
        Clear: "حذف",
        "Clear All": "حذف الكل",
        Make: "الصنع",
        Model: "الموديل",
        make: "الصنع",
        model: "الموديل",
        Trim: "الفئة",
        "Engine Size": "سعة المحرك",
        "Number of Cylinders": "عدد السيلندرات",
        Transmission: "ناقل الحركة",
        "Body Type": "نوع الهيكل",
        "Drive Type": "نظام الدفع",
        Colors: "الألوان",
        Payment: "طريقة الدفع",
        "Fuel Type": "نوع الوقود",
        "fuel type": "نوع الوقود",
        Registration: "التسجيل",
        Insurance: "التأمين",
        Filters: "فلترة",
        "Country Specs": "وارد",
        "(Oldest First)": "(الأقدم أولاً)",
        "(Newest First)": "(الأحدث أولاً)",
        "(Low to High)": "(الأقل إلى الأعلى)",
        "(High to Low)": "(الأعلى إلى الأقل)",
        "(Lowest First)": "(الأقل أولاً)",
        "(Highest First)": "(الأعلى أولاً)",
        "Condition Score": "نتيجة الحالة",
        Results: "نتيجة",
        Odometer: "عداد المسافة",
        OdometerFilter: "الكيلومترات",
        Location: "الموقع",
        "Oldest Listings First": "القوائم الأقدم أولاً",
        "Newest Listings First": "أحدث القوائم أولاً",
        "Highest Price First": "أعلى سعر أولاً",
        "Lowest Price First": "أقل سعر أولاً",
        "Highest Score First": "أعلى نتيجة أولاً",
        "Lowest Score First": "أقل نتيجة أولاً",
        "Number of listings": "عدد القوائم",
        "Vehicle Details": "تفاصيل السيارة",
        "Vehicle Features": "ميزات السيارة",
        "No Features available for this car!":
          "لا توجد ميزات متاحة لهذه السيارة!",
        "Markabte Inspection Score": "تقييم مركبتي",
        "View Full Report": "عرض التقرير الكامل",
        "Edit Car": "تعديل السيارة",
        Listing: "قائمة",
        "out of": "من",
        "Inspection Report": "تقرير الفحص",
        "Markabte Inspection Details": "تفاصيل فحص مركبتي",
        "Inspection Date": "تاريخ الفحص :",
        "Inspected By": "تم الفحص بواسطة :",
        "Inspection Points": "نقاط الفحص :",
        "Overall Inspection Score": "نتيجة الفحص العامة",
        "Detailed Inspection Points": "نقاط الفحص التفصيلية",
        "Show inspection points with faults only":
          "إظهار نقاط الفحص ذات العيوب فقط",
        "Markabte Officer Comments": "ملاحظات موظف مركبتي",
        "No comments": "لا يوجد ملاحظات",
        "Back To Vehicle Detail Page": "العودة الى صفحة تفاصيل السيارة",
        "Incoming Requests": "قائمة الطلبات الواردة  ",
        "Claimed Requests": "قائمة طلباتي",
        "You have": "لديك",
        "appointments to confirm.": "مواعيد يجب تأكيدها.",
        submitted: " قدمت : منذ",
        "hour ago": "ساعة مضت",
        Claim: "حجز الطلب",
        continue: " ... متابعة الادخال",
        Continue: "المتابعة",
        GPS: "الموقع",
        "List Vehicle": "بدء الادخال",
        Listed: "ادرجت : منذ",
        "days ago": "ايام",
        Km: "كم",
        Mile: "ميل",
        JD: "د.أ",
        "english JD": "",
        "arabic JD": " د.ا",
        "/ Month .": "/ شهر .",
        " months . JD": "شهر . ",
        "K down": "ألف دينار دفعة أولى",
        "View Vehicle": "عرض السيارة",
        publish: "نشر",
        "View Exterior": "مظهر خارجى",
        "View Interior": "مظهر داخلي",
        "Driver Front": "من الامام",
        "Passenger Rear": "من الخلف",
        Front: "منظور السائق",
        Back: "منظور الراكب",
        "3rd row": "الصف الثالت",
        Trunk: "الصندوق",
        "dealer staff": "موظف التاجر",
        "dealer owner": "مالك تاجر",
        "Edit Price": "تعديل السعر",
        "Report Sale": "الإبلاغ عن بيع سيارة",
        "Please select the type of user this vehicle was sold to":
          "الرجاء تحديد نوع المستخدم الذي تم بيع هذه السيارة له!",
        "Markabte User": "مستخدم مركبتي",
        "Independent Customer": "مشتري مستقل",
        "Edit car price": "تعديل سعر السيارة",
        "Sign Up For an Account": "إنشاء حساب",
        "1.Book Your Appointment": "1. احجز موعدك",
        "2.Visit Seller & Check The Car":
          "2. قم بزيارة البائع و تحقق من السيارة",
        "3.Buy Your Desired Car": "3. اشتري سيارتك",
        "4.Notify Us": "4. أعلمنا عن سيارتك الجديدة!",
        phone: "الهاتف",
        Phone: "الهاتف",
        email: "الايميل",
        "Invalid Credentials": "الصلاحيات غير صحيحة",
        Female: "انثى",
        Male: "ذكر",
        Delete: "حذف",
        "No, but want to re-schedule? Re-schedule":
          "ﻟﺎ ، ﻫﻞ ﺗﺮﻳﺪ اﻟﺬﻫﺎب ﻓﻲ وﻗﺖ آﺧﺮ؟ إﻋﺎدة اﻟﺠﺪوﻟﺔ",
        "No, but want to re-schedule?": "لا, هل تريد الذهاب في وقت آخر؟",
        "Re-schedule": "إعادة الجدولة",
        "Did you go to the following appointment?":
          "ﻫﻞ ذﻫﺒﺖ إﻟﻰ اﻟﻤﻮﻋﺪ اﻟﺘﺎﻟﻲ؟",
        "Confirm Attendance": "ﺗﺄﻛﻴﺪ اﻟﺤﻀﻮر",
        "No, I Didn’t Go": "ﻟﺎ ، ﻟﻢ أذﻫﺐ",
        "Yes, I Went": "ﻧﻌﻢ ، ذﻫﺒﺖ",
        "Deleted appointments cannot be retrevied.":
          "ﻟﺎ ﻳﻤﻜﻦ اﺳﺘﺮﺟﺎع اﻟﻤﻮاﻋﻴﺪ اﻟﻤﺤﺬوﻓﺔ.",
        "Are you sure you want to delete the appointment?":
          "ﻫﻞ اﻧﺖ ﻣﺘﺄﻛﺪ ﻣﻦ ﺣﺬف اﻟﻤﻮﻋﺪ ؟",
        "Gender (Optional)": "الجنس (اختياري)",
        "First Name": "الاسم الأول",
        "Last Name": "اسم العائلة",
        "password does not match confirmation": "كلمة السر لاتطابق التأكيد",
        "Passwords must match.": "ﻳﺠﺐ ان ﺗﺘﻄﺎﺑﻖ ﻛﻠﻤﺔ اﻟﻤﺮور. ",
        "invalid mobile number": "رقم الهاتف غير صالح",
        "invalid Email address": "صيغة الايميل غير صالحة",
        "this field is required": "هذه الخانة مطلوبة",
        "How it works": "آلية العمل",
        "Book your appointment to view the car in person anytime!":
          "احجز موعدك  لرؤية السيارة شخصيا في أي وقت!",
        "Meet the seller and have an up close look at your future car.":
          "قابل البائع و ألق نظرة على سيارتك المستقبلية عن قرب",
        "Seal the deal with the seller and celebrate your new car!":
          "أنهي الصفقة مع البائع واحتفل بسيارتك الجديدة !",
        "Do not forget to notify us about your car purchase.":
          "لا تنسى اعلامنا عند شرائك السيارة",
        "Request Listing Entry": "طلب ادخال سيارة",
        "Request Officers for Vehicle Listing": "طلب موظف ادخال المركبات",
        "Please specify the number of vehicles that need to be listed by our officers":
          "الرجاء تحديد عدد المركبات التي يجب أن يتم ادخالها من قبل مندوبينا!",
        "Number Of Vehicles": "ادخل عدد السيارات",
        "Verify Your Mobile Number": "التحقق من رقم الهاتف",
        Confirm: "تأكيد",
        Close: "إغلاق",
        "Sign In": "تسجيل الدخول",
        Email: "البريد الإلكتروني",
        "Email Address": "البريد الإلكتروني",
        Password: "كلمه السر",
        PassConfirm: "التأكد من كلمة السر",
        "incorrect password": "كلمة السر غير صالحة",
        "forgot password": "هل نسيت كلمة السر ؟",
        "Forgot password?": "ﻧﺴﻴﺖ ﻛﻠﻤﺔ السر ؟",
        "Hurray, No issues were found": "مرحى ، لم يتم العثور على مشكلات!",
        Exterior: "خارجي",
        Interior: "داخلي",
        EXTERIOR: "خارجي",
        INTERIOR: "داخلي",
        "Exterior Issues": "المشاكل الخارجية",
        "Interior Issues": "المشاكل الداخلية",
        "Full Inspection Report": "التقرير الكامل لحالة المركبة",
        "Drive Front View": "منظر أمامي للسائق",
        "Passenger Rear View": "الرؤية الخلفية للراكب",
        "Driver Angle": "زاوية السائق",
        "Passenger Angle": "زاوية الراكب",
        "3rd Row View": "عرض الصف الثالث",
        "Rear View": "رؤية خلفية",
        "Front View": "منظر أمامي",
        Minor: "ثانوي",
        Major: "رئيسي",
        "Vehicle Details Page": "صفحة تفاصيل السيارة",
        "Vehicle Information": "معلومات السيارة",
        "Vehicle Condition": "حالة المركبة",
        "Show full report": "عرض التقرير كامل",
        "View full report": "عرض التقرير كامل",
        "Inspection Points": "نقطة تفتيش",
        "Based on our FREE ": "بناءا على",
        "+ point condition report": "+ نقطة في فحصنا المجاني لحالة المركبة",
        Incoming: "الوارد  ",
        Claimed: "طلباتي",
        "List Car": "بدء الادخال",
        "Join Our Netwrok": "انضم الى شبكتنا",
        "Edit Content": "تعديل المحتوى",
        "Publish Vehicle": "نشر المركبة",
        "Publish Vehicle?": "نشر المركبة؟",
        "Are you sure that you want to publish the vehicle listing?":
          "هل أنت متأكد أنك تريد نشر المركبة؟",
        Cancel: "إلغاء",
        of: "من",
        "Front Angle": "الزاوية الأمامية",
        "Rear Angle": "الزاوية الخلفية",
        Driver: "السائق",
        "Driver View": "السائق",
        Passenger: "الراكب",
        "Passenger View": "الراكب",
        "3rd Row": "الصف الثالت",
        "Trunk View": "الصندوق",
        "Angle Issues List": "قائمة مشاكل الزاوية",
        "View List": "عرض القائمة",
        "forgot password": "هل نسيت كلمة السر؟",
        "Send Reset Link": "إرسال رابط إعادة التعيين",
        "Minor Issues": "مشاكل صغيرة",
        "Major Issues": "مشاكل كبيرة",
        "Please enter your email and we will send you a reset link.":
          "أدخل بريدك الإلكتروني وسنرسل لك رابط إعادة تعيين.",
        "Forgot Your Password?": "هل نسيت كلمة السر؟",
        "Forgot your password?": "هل نسيت كلمة السر؟",
        Connect: "تواصل",
        "with serious car buyers.": "مع مشتري السيارات الجادين.",
        "Back to Sign In": "تسجيل الدخول",
        "Please enter your new password.":
          "الرجاء إدخال كلمة السر الجديدة الخاصة بك.",
        "New Password": "كلمة السر الجديدة",
        "Confirm Password": "تأكيد كلمة السر",
        "Reset Your Password": "إعادة تعيين كلمة السر",
        "Reset Password": "إعادة تعيين كلمة السر",
        "Sign In to Your Account": "تسجيل الدخول إلى حسابك",
        "Keep me signed in": "ابقني مسجل",
        "What do you want to do next?": "ماذا تريد ان تفعل لاحقا؟",
        "Vehicle Condition Report": "تقرير حالة المركبة",
        "Inspection Report Summary": "تقرير مركبتي لحالة السيارة",
        "Markabte Officer": "موظف مركبتي",
        "Inspected On": "تاريخ الفحص",
        "Report Summary": "ملخص التقرير",
        "Vehicle Visual Condition": "الحالة البصرية للمركبة",
        "Click on the colored markers to see issue details.":
          "اضغط على العلامات الملوّنة لرؤية تفاصيل الملاحظة",
        "Tap to view vehicle cosmetic issues.": ".انقر لعرض مشاكل المركبة",
        Note: "ملاحظة",
        "View Visual Cosmetic Report": "معاينة التقرير المرئي",
        "View Visual Report": "شاهد الحالة المرئية",
        "Visual Report": "الحالة المرئية",
        "Markabte Full Condition Report": "تقرير مركبتي الكامل للحالة",
        Disclaimer: "إخلاء مسؤولية",
        "Report Car Sale": "الإبلاغ عن بيع سيارة",
        "You are successfully connected to live changes":
          "تم الاتصال بنجاح بالتغييرات الحية",
        "Please make sure you entered valid credentials":
          "الرجاء التأكد من إدخال بيانات اعتماد صالحة",
        "Your passwords do not match!": "كلمات السر الخاصة بك لا تتطابق!",
        "Password must be 8 characters. Minimum one number and one letter, without spaces.":
          "ﻳﺠﺐ أن ﺗﺘﻜﻮن ﻛﻠﻤﺔ اﻟﻤﺮور ﻣﻦ 8 أﺣﺮف. رﻗﻢ واﺣﺪ ﻋﻠﻰ اﻟﺄﻗﻞ وﺣﺮف واﺣﺪ ﺑﺪون ﻣﺴﺎﻓﺎت.",
        "account activated! please login with your new password":
          "تم تفعيل الحساب! الرجاء تسجيل الدخول باستخدام كلمة السر الجديدة",
        error: "خطأ",
        "Your password reset reqest has been sent to your email.":
          "تم إرسال طلب إعادة تعيين كلمة السر إلى بريدك الإلكتروني.",
        "Please make sure you entered a valid email address":
          "يرجى التأكد من إدخال عنوان بريد إلكتروني صالح",
        "Car sale status successfully updated":
          "تم تحديث حالة بيع السيارة بنجاح",
        "Oops! an error has occured! please try again.":
          "عفوًا! حدث خطأ! يرجى المحاولة مرة أخرى.",
        "Your changes have been saved": "تم حفظ التغييرات",
        "There was an error sending your request please try again":
          "حدث خطأ أثناء إرسال طلبك ، يرجى المحاولة مرة أخرى",
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s exterior":
          "نقوم بإجراء فحص نظري لتحديد نوع و موقع و شدة الأضرار الخاصة بالهيكل الخارجي للمركبة",
        "We measure and analyze the vehicle’s paint thickness readings to identify repainted panels from panels with original paint.":
          "نقوم بقياس و تحليل قراءات سماكة الطلاء الخاصة بالمركبة لتحديد اللوحات المدهونة من اللوحات ذات الطلاء الأصلي",
        "We measure temperature of cooling/heating systems & check air throw for all AC vents.":
          "نقوم بقياس درجة حرارة نظام التكييف / التدفئة ونتحقق من تحكم الهواء لجميع فتحات التكييف.",
        "We perform a visual inspection to identify the type, location, and severity of cosmetic issues to the vehicle’s interior":
          "نقوم بإجراء فحص نظري لتحديد نوع و موقع و شدة الأضرار الخاصة بالمقصورة الداخلية للمركبة",
        "We test the functionality of various control parts of the vehicle (Buttons, Controls, Systems) to identify what works and what does not":
          "نختبر وظائف أجزاء التحكم المتعددة بالمركبة (أزرار، مفاتيح، أنظمة) لتحديد ما يعمل و ما لا يعمل",
        "We scan the vehicle’s on-board computer to diagnose and identify fault codes within mechanical and electrical systems of the vehicle":
          "نقوم بإجراء فحص خاص بكمبيوتر المركبة لتشخيص وتحديد رموز الأعطال الخاصة بالأنظمة الميكانيكية والإلكترونية للمركبة",
        "Vehicle’s frame inspection report is provided by a 3rd inspection party, please take note of the inspection’s date":
          "تقرير فحص شاصي المركبة مقدم من جهة فحص أخرى٬ يرجى ملاحظة تاريخ الفحص",
        "We determine the overall condition based on the remaining tread depth, age, and the tire’s visual inspection results (cracking, bulges)":
          "نحدد الحالة العامة للإطار بناءً على عمق الفرزة المتبقي٬ العمر٬ ونتائج الفحص النظري للإطار (تشققات أو فقاعات)",
        "We check the availability of standard equipment in the vehicle":
          "نقوم بالتحقق من توافر المعدات الأساسية في المركبة",
        "Tires need replacement if age >5 yrs, Remaining Tread depth <1.6mm or if it has bulges or cracking.":
          "تحتاج الإطارات إلى تبديل إذا كان العمر >5 سنوات٬ عمق الفرزة المتبقي <1.6 مم أو عند تواجد فقاعات أو تشققات.",
        Excellent: "ممتاز",
        "Minor Issue": "ملاحظة بسيطة",
        "Major Issue": "ملاحظة كبيرة",
        "This Angle has no Minor Issue":
          "لا يوجد اي ملاحظات صغيرة في هذه الزاوية",
        "This Angle has no Major issue":
          "لا يوجد اي ملاحظات كبيرة في هذه الزاوية",
        "Origional Paint": "الطلاء الأصلي",
        Repainted: "مدهون",
        Working: "يعمل",
        "Frame Issues": "ملاحظات",
        "Not Working": "لا يعمل",
        "Additional Information": "معلومات إضافية",
        "No codes": "لا أعطال",
        "Minor issues": "أعطال بسيطة",
        "Major issues": "أعطال كبيرة",
        Good: "جيد",
        Monitor: "مراقبة",
        Replace: "تبديل",
        Available: "متوفر",
        "Not Available": "غير متوفر",
        "Available Cars Only": "السيارات المتاحة فقط",
        "Welcome Back!": "مرحبا بعودتك!",
        firstArabicLoginText: "مندوبون",
        secondArabicLoginText: "زبائن ",
        DEDICATED: " متأهبون",
        "officers at your fingertips,": "في خدمتك",
        QUALIFIED: "مؤهلين",
        "buyers to your dealership.": "في معرضك",
        "There where no Major issues spotted in this view!":
          "لا يوجد مشاكل كبيرة من هذا المنظور",
        "There where no Minor issues spotted in this view!":
          "لا يوجد مشاكل صغيرة من هذا المنظور",
        "dealer owner": "مالك المعرض",
        "dealer staff": "موظف مبيعات",
        "The maximum number of vehicles is 50":
          "الحد الأقصى لعدد المركبات هو 50",
        Home: "الرئيسية",
        "All Results": "جميع النتائج",
        "Save Search": "حفظ البحث",
        "Book An Appointment": "احجز موعد",
        "please enter city": "يرجى إدخال المدينة",
        "Go To Homepage": "الانتقال إلى الصفحة الرئيسية",
        Homepage: "الصفحة الرئيسية",
        "We couldn’t find the page you are looking for.":
          "لم نتمكن من العثور على الصفحة التي تبحث عنها.",
        amman: "عمان",
        aqaba: "العقبة",
        jerash: "جرش",
        irbid: "اربد",
        madaba: "مادبا",
        zarqa: "الزرقاء",
        kerak: "الكرك",
        mafraq: "المفرق",
        "Al-Mafraq": "المفرق",
        ajloun: "عجلون",
        salt: "السلط",
        "As-Salt": "السلط",
        "ma'an": "معان",
        "at-tafilah": "الطفيلة",
        "New Search": "بحث جديد",
        "Mobile number already taken.": "رﻗﻢ اﻟﻬﺎﺗﻒ ﻣﺴﺘﻌﻤﻞ.",
        "Email already taken.": "البريد الالكتروني ﻣﺴﺘﻌﻤل",
        Filter: "فلتر",
        "Active Filters": "فلتر فعال",
        "Sign In | Sign Up": "تسجيل الدخول | انشاء حساب",
        "Please enter your password below": "الرجاء ادخال كلمة السر",
        "Change Email Address": "عدل البريد الأكتروني",
        "Change Phone Number": "عدل رقم الهاتف",
        "phone Number": "رقم الهاتف",
        "Edit your profile information.": "عدل بيانات ملفك الشخصي",
        "Enter a new password.": "عدل كلمة السر",
        Explore: "اكتشف",
        Homepage: "الصفحة الرئيسية",
        "Used Cars": "السيارات المستعملة",
        "Inspection Process": "عملية الفحص",
        dealername: "اسم التاجر",
        FAQ: "الأسئلة المتكررة",
        "My Garage": "كراجي",
        About: "حول التطبيق",
        "Account Info": "معلومات الحساب",
        "General Info": "معلومات عامة",
        "Back to Main Menu": "العودة الى القائمة الرئيسية",
        "Our Company": "شركتنا",
        "About Markabte": "عن مركبتي",
        "Markabte Officer": "مندوبين مركبتي",
        "Contact Us": "تواصل معنا",
        "Become an Officer": "اعمل كمندوب",
        "Join Our Network": "انضم الى شبكتنا",
        Careers: "الوظائف",
        "Dealership Network": "شبكة التجار",
        Legal: "قانونياً",
        "Privacy Policy": "سياسة الخصوصية",
        "Terms & Conditions": "الشروط والاحكام",
        "Follow Us": "تابعنا",
        More: "المزيد",
        Arabic: "English",
        "My Account": "حسابي",
        Change: "تغيير",
        "I agree that the dealer will photograph the car license if I buy the car.":
          "أوافق على أن يقوم التاجر بتصوير رخصة المركبة في حال شرائي للمركبة",
        Next: "التالي",
        Myself: "أنا",
        "Someone Else": "شخص آخر",
        "Dealership is currently closed!": "المعرض مغلق حاليا!",
        "Saved Searches": "البحوث المحفوظة",
        "Update Account": "حفظ التغييرات",
        "Saved Listing": "المفضلة",
        "My Appointments": "مواعيدي",
        Appointments: "المواعيد",
        Scheduled: "مواعيد قادمة",
        History: "مواعيد سابقة",
        Saved: "المفضلة",
        "Account Settings": "إعدادت الحساب",
        "Search For Car": "ابحث عن سيارة",
        "Explore Pre-inspected Vehicles.": "ابحث عن السيارات المفحوصة مسبقاً",
        "For Free": "مجاناً!",
        "100% Free": "مجاناً 100%",
        "Transparent Results": "الثقة والشفافية",
        "Pre-Inspected Cars": "مركبات مفحوصة مسبقاً",
        "Markabte Difference": "لماذا تختار مركبتي",
        "Why Choose Us?": "لماذا تختارنا؟",
        "Choose Arrival Time": "اختر وقت الحضور",
        "Pre-Inspected Vehicles": "مركبات مفحوصة مسبقاً",
        "Trust and Transparency": "الثقة والشفافية",
        Free: "مجاناً",
        "Learn More >": "إقرأ المزيد >",
        "Detailed, comprehensive and user friendly report available upfront.":
          "ﺗﻘﺮﻳﺮ ﺗﻔﺼﻴﻠﻲ, ﺸﺎﻣﻞ و ﺳﻬﻞ اﻟﺎﺳﺘﺨﺪام ﻣﺘﻮﻓﺮ ﻣﺴﺒﻘﺎً ﻟﻜﻞ ﻣﺮﻛﺒﺔ ﻋﺒﺮ ﻣﻨﺼﺔ ﻣﺮﻛﺒﺘﻲ.",
        "Independent officers, inspect cars using advanced equipment and professional techniques.":
          "ﻣﻨﺪوﺑﻴﻦ ﻣﺴﺘﻘﻠﻴﻦ و ﻣﺪﺮﺑﻴﻦ ﻟﻔﺤﺺ اﻟﻤﺮﻜﺒﺎت ﺑﺎﺳﺘﺨﺪام أﺟﻬﺰة ﻣﺘﻄﻮرة و أﺳﺎﻟﻴﺐ ﻣﻌﺘﻤﺪة.",
        "Browse cars, connect with sellers and buy your car for FREE!":
          "ﺗﺼﻔﺢ اﻟﻤﺮﻜﺒﺎت, ﺗﻮاﺻﻞ ﻣﻊ اﻟﺒﺎﺋﻌﻴﻦ و اﺷﺘﺮي ﻣﺮﻛﺒﺘﻚ ﻣﺠﺎﻧﺎً!",
        "How It Works": "آﻟﻴﺔ اﻟﻌﻤﻞ",
        "Book Your Appointment": "اﺣﺠﺰ ﻣﻮﻋﺪك",
        "Visit Seller & Check The Car": "ﻗﻢ ﺑﺰﻳﺎرة اﻟﺒﺎﺋﻊ وﺗﺤﻘﻖ ﻣﻦ اﻟﻤﺮﻛﺒﺔ",
        "Buy Your Desired Car": "اﺷﺘﺮي ﺑﻤﺮﻛﺒﺘﻚ اﻟﻤﺴﺘﻬﺪﻓﺔ",
        "Notify Us": "أﻋﻠﻤﻨﺎ ﻋﻦ ﻣﺮﻛﺒﺘﻚ اﻟﺠﺪﻳﺪة !",
        BookAppointmentText: "اﺣﺠﺰ ﻣﻮﻋﺪك ﻟﺮؤﻳﺔ اﻟﻤﺮﻛﺒﺔ ﺷﺨﺼﻴﺎَ ﻓﻲ أي وﻗﺖ!",
        VisitSellerText:
          "ﻗﺎﺑﻞ اﻟﺒﺎﺋﻊ و أﻟﻖ ﻧﻈﺮة ﻋﻠﻰ ﻣﺮﻛﺒﺘﻚ اﻟﻤﺴﺘﻘﺒﻠﻴﺔ ﻋﻦ ﻗﺮب. ",
        BuyCarText: "اﺧﺘﻢ اﻟﺼﻔﻘﺔ ﻣﻊ اﻟﺒﺎﺋﻊ و اﺣﺘﻔﻞ ﺑﻤﺮﻛﺒﺘﻚ اﻟﺠﺪﻳﺪة!",
        NotifyUsText: "ﻟﺎ ﺗﻨﺴﻰ إﻋﻠﺎﻣﻨﺎ ﻋﻦ ﺷﺮاﺋﻚ ﻟﻤﺮﻛﺒﺔ. ",
        "By Make/Model": "حسب الصنع/الموديل",
        "By Fuel Type": "حسب نوع الوقود",
        "All Makes": "جميع الصناعات",
        Models: "الموديل",
        "All Fuel Types": "جميع انواع الوقود",
        "Year (From)": "السنة (من)",
        "Year (To)": "السنة (إلى)",
        "Price (From)": "السعر (من)",
        "Price (To)": "السعر (إلى)",
        "Search For Cars": "ابحث عن مركبة",
        authenticatedAppointmentsParagraph:
          "يمكنك إدارة مواعيدك، والوصول إلى معلومات التاجر ومعلومات الموقع من حسابك.",
        "Interested In This Car ?": "هل انت مهتم بهذه السيارة ؟",
        "Use your points to unlock the seller’s information.":
          "استخدم نقاطك لكشف معلومات البائع.",
        "Book Free Appointment": "احجز موعد مجاناً",
        "View All": "مشاهدة الكل",
        disclaimerParagraph:
          "Call 042 - 111 WHEELS (042- 111 943 357) for pre-delivery inspection of PakWheels Certified Car. Incase of failure to perform pre-delivery inspection, PakWheels.com will not be ye, hence it can be possible for such paint to go undetected.",
        "Visual Inspection Report": "تقرير الفحص المفصل",
        "View Faults Only": "عرض المشاكل فقط",
        faults: "أعطال",
        Faults: "أعطال",
        "No Faults": "لا أعطال",
        "Show more": "أظهر المزيد",
        "Show less": "عرض أقل",
        Kilometer: "كيلومترات",
        "2021 Markabte. All Rights Reserved.":
          "جميع الحقوق محفوظة لموقع مركبتي 2021",
        العربية: "English",
        "All Options": "كافة الخيارات",
        "0 listings match your search criteria. The following search criteria(s) have been removed:":
          "0 من النتائج تطابق معايير البحث الخاصة بك. لقد تمت ازالة معايير البحث التالية",
        removedCriteriaText: "لا يوجد سيارة تطابق كل معايير البحث.",
        أسود: "black",
        أبيض: "white",
        فضي: "silver",
        رمادي: "grey",
        أحمر: "red",
        أزرق: "blue",
        أخضر: "green",
        بني: "brown",
        بيج: "beige",
        خمري: "burgundy",
        ذهبي: "gold",
        أصفر: "yellow",
        برتقالي: "orange",
        بنفسجي: "purple",
        كحلي: "Navy Blue",
        "By signing up, you agree to our": "ﺑﺘﺴﺠﻴﻠﻚ ﻋﻠﻰ اﻟﻤﻮﻗﻊ اﻧﺖ ﺗﻮاﻓﻖ ﻋﻠﻰ ",
        "Terms and Privacy Policy": "اﻟﺸﺮوط واﻟﺄﺣﻜﺎم",
        "Sign Up": "انشاء حساب",
        "Sign up": "اﻧﺸﺊ ﺣﺴﺎب",
        "Already have an account?": "ﺗﻤﺘﻠﻚ ﺣﺴﺎب ؟",
        "Don’t have an account?": "ﻟﻴﺲ ﻟﺪﻳﻚ ﺣﺴﺎب ؟",
        "Verify Your Number": "اﻟﺘﺤﻘﻖ ﻣﻦ رﻗﻢ اﻟﻬﺎﺗﻒ",
        "Please enter the verification code sent to ":
          "رﺟﺎءً ادﺧﻞ اﻟﺮﻣﺰ اﻟﻤﺮﺳﻞ اﻟﻰ",
        "Please enter the code sent to your number":
          "الرجاء ادخال الرمز المرسل لهاتفك",
        "The code you entered is incorrect.Try again":
          "اﻟﺮﻣﺰ اﻟﺬي أدﺧﻠﺘﻪ ﻏﻴﺮ ﺻﺤﻴﺢ",
        "Verify and Continue": "ﺗﺤﻘﻖ واﻟﻤﺘﺎﺑﻌﺔ",
        "Didn't receive a code?": "ﻟﻢ ﻳﺼﻠﻚ اﻟﺮﻣﺰ ؟",
        "Resend code": "إﻋﺎدة ارﺳﺎل الرمز",
        Resend: "إﻋﺎدة ارﺳﺎل الرمز",
        in: "ﺧﻠﺎل",
        "Wrong Mobile number?": "رﻗﻢ اﻟﻬﺎﺗﻒ ﻏﻴﺮ ﺻﺤﻴﺢ ؟",
        "Edit Mobile Number": "إﻋﺎدة ادﺧﺎل اﻟﺮﻗﻢ",
        "Edit Number": "إﻋﺎدة ادﺧﺎل اﻟﺮﻗﻢ",
        "Current Password": "كلمة السر الحالية",
        "Confirm New Password": "التحقق من كلمة السر الجديدة",
        "Code entered is incorrect. Please try again.":
          "الرﻣﺰ اﻟﺬي أدﺧﻠﺘﻪ ﻏﻴﺮ صحيح، ﻳﺮﺟﻰ اﻟﻤﺤﺎوﻟﺔ مجددا.",
        "Scheduled Appointments": "اﻟﻤﻮاﻋﻴﺪ اﻟﻘﺎدﻣﺔ",
        "You will receive a gift once you buy a car as a thank you from us.":
          "ﺳﺘﺤﺼﻞ ﻋﻠﻰ ﻫﺪﻳﺔ ﻋﻨﺪ ﺷﺮاﺋﻚ ﻣﺮﻛﺒﺔ ﻛﺸﻜﺮ ﻣﻨﺎ.",
        "Learn More": "أﻋﺮف أﻛﺜﺮ",
        "You Have No Scheduled Appointments.": "ﻟﺎ ﻳﻮﺟﺪ ﻟﺪﻳﻚ أي ﻣﻮاﻋﻴﺪ ﻣﺤﺠﻮزة.",
        "When you book an appoitnment, you’ll find it here.":
          "ﻋﻨﺪﻣﺎ ﺗﻘﻮم ﺑﺤﺠﺰ ﻣﻮﻋﺪ, ﺳﺘﺠﺪه ﻫﻨﺎ.",
        "Appointments History": "اﻟﻤﻮاﻋﻴﺪ اﻟﺴﺎﺑﻘﺔ",
        "Your gift is waiting for you. Did you buy any of these cars?":
          "ﻫﺪﻳﺘﻚ ﻓﻲ اﻧﺘﻈﺎرك. ﻫﻞ اﺷﺘﺮﻳﺖ أيًا ﻣﻦ ﻫﺬه اﻟﺴﻴﺎرات؟",
        "Notify Us Here!": "اﺧﺒﺮﻧﺎ ﻫﻨﺎ !",
        "You Have No Previous Appointments!": "ﻟﺎ ﻳﻮﺟﺪ ﻟﺪﻳﻚ أي ﻣﻮاﻋﻴﺪ ﺳﺎﺑﻘﺔ!",
        "When you confirm attendance on an appointment, you’ll find it here.":
          "ﻋﻨﺪﻣﺎ ﺗﻘﻮم ﺑﺘﺄﻛﻴﺪ ﺣﻀﻮرك ﻟﺄي ﻣﻮﻋﺪ, ﺳﺘﺠﺪه ﻫﻨﺎ.",
        "Appointment Date & Time": "وقت وتاريخ الموعد",
        "Select your appointment date and time below":
          "اختر اليوم والوقت المناسب لك:",
        "*Booking an appointment does not prevent the car from being sold to another buyer.":
          "*حجز الموعد لا يمنع بيع المركبة لمشتري آخر",
        "Appointment Attendee": "معلوماتك",
        "I agree to infrom Markabte team if I buy any car from their platform.":
          "  أواﻓﻖ ﻋﻠﻰ اﺑﻠﺎغ ﻓﺮﻳﻖ ﻣﺮﻛﺒﺘﻲ ﻋﻨﺪ ﺷﺮاﺋﻲ أي ﻣﺮﻛﺒﺔ ﻣﻦﻣﻨﺼﺔ ﻣﺮﻛﺒﺘﻲ .",
        Today: "اليوم",
        Tomorrow: "غداً",
        Sunday: "الأحد",
        Monday: "الأثنين",
        Tuesday: "الثلاثاء",
        Wednesday: "الأربعاء",
        Thursday: "الخميس",
        Friday: "الجمعة",
        Saturday: "السبت",
        back: "رجوع",
        "Change Mobile Number": "تغيير رقم الهاتف",
        "Save Changes": "حفظ التغييرات",
        "Delete Appointment": "حذف الموعد",
        "Appointment Updated Successfuly": "تم تعديل الموعد بنجاح",
        "process done successfuly": "تمت العملية بنجاح",
        Jan: "يناير",
        Feb: "فبراير",
        Mar: "مارس",
        Apr: "أبريل",
        May: "مايو",
        Jun: "يونيو",
        Jul: "يوليو",
        Aug: "أغسطس",
        Sep: "سبتمبر",
        Oct: "أكتوبر",
        Nov: "نوفمبر",
        Dec: "ديسمبر",
        January: "يناير",
        February: "فبراير",
        March: "مارس",
        April: "أبريل",
        May: "مايو",
        June: "يونيو",
        July: "يوليو",
        August: "أغسطس",
        September: "سبتمبر",
        October: "أكتوبر",
        November: "نوفمبر",
        December: "ديسمبر",
        "appointment successfuly booked": "تم حجز الموعد بنجاح",
        "data successfuly updated": "تم تحديث البيانات بنجاح",
        "Manage your appointment and access dealer’s information and location from your garage.":
          "ﻳﻤﻜﻨﻚ إدارة مواعيدك ، واﻟﻮﺻﻮل إﻟﻰ ﻣﻌﻠﻮﻣﺎت اﻟﺘﺎﺟﺮ  و موقعه ﻣﻦ كراجك.",
        "Appointment Successfully Booked!": "ﺗﻢ ﺣﺠﺰ اﻟﻤﻮﻋﺪ ﺑﻨﺠﺎح !",
        "Please enter your new number below": "الرجاء ادخال رقم الهاتف الجديد",
        "Check your personal information below":
          "ﺗﺤﻘﻖ ﻣﻦ ﻣﻌﻠﻮﻣﺎﺗﻚ اﻟﺸﺨﺼﻴﺔ أدﻧﺎه",
        rewardDialogText: "",
        AM: "ﺻﺒﺎﺣﺎً",
        PM: "مساءً",
        "Edit Time": "ﺗﻐﻴﻴﺮ اﻟﻮﻗﺖ",
        "Get Directions": "اﺣﺼﻞ ﻋﻠﻰ اﻟﺎﺗﺠﺎﻫﺎت",
        "Dealership Information": "ﻣﻌﻠﻮﻣﺎت اﻟﺘﺎﺟﺮ",
        "Appointment Cancelled": "ﺗﻢ إﻟﻐﺎء اﻟﻤﻮﻋﺪ",
        Today: "اليوم",
        Appointments: "ﻣﻮاﻋﻴﺪ",
        Appointment: "ﻣﻮﻋﺪ",
        arabicFirstSoldAppointment: "ﻟﻘﺪ ﺗﻢ اﻟﻐﺎء",
        "car was sold from your scheduled appoitnemnts.":
          "ﻣﻦ ﻣﻮاﻋﻴﺪك ﺑﺴﺒﺐ ﺑﻴﻊ اﻟﻤﺮﻛﺒﺔ.",
        "cars were sold from your scheduled appoitnemnts.":
          "ﻣﻦ ﻣﻮاﻋﻴﺪك ﺑﺴﺒﺐ ﺑﻴﻊ اﻟﻤﺮﻛﺒﺔ.",
        "Car Registeration Information": "معلومات تسجيل المركبة",
        "The car will be registered in the name of":
          "في حال شراء المركبة، سيتم تسجيل المركبة باسم:",
        System: "النظام",
        Description: "الوصف",
        "Search for more information": "ابحث عن مزيد من المعلومات",
        "(3rd Party)": "(جهة فحص أخرى)",
        "(Markabte)": "(مركبتي)",
        Faults: "أعطال",
        Good: "جيد",
        "Markabte Score": "نتيجة مركبتي",
        August: "اغسطس",
        "Good/ Good+Issues": "جيد/ جيد+ملاحظات",
        Issues: "ملاحظات",
        good: "جيد",
        "No Issues": "لا توجد ملاحظات",
        "Report Image": "صورة التقرير",
        "Markabte Inspection": "ﻓﺤﺺ ﻣﺮﻛﺒﺘﻲ",
        "The Right Way to Inspect!": "اﻟﻄﺮﻳﻘﺔ اﻟﺼﺤﻴﺤﺔ ﻟﻠﻔﺤﺺ!",
        Our: "ﻓﺤﺼﻨﺎ",
        FREE: "اﻟﻤﺠﺎﻧﻲ",
        "Inspection Is": "",
        "Our Inspection Categories Are": "ﻓﺌﺎت ﻓﺤﺼﻨﺎ ﻫﻲ",
        Comprehensive: "شامل",
        Reliable: "موثوق",
        "User Friendly": "ﺳﻬﻞ اﻟﺎﺳﺘﺨﺪام",
        "Covers 250+ inspection points of critical areas of the car.":
          "ﻳﺸﻤﻞ 250+ ﻧﻘﻄﺔ ﻓﺤﺺ أﺳﺎﺳﻴﺔ ﺑﺎﻟﻤﺮﻛﺒﺔ.",
        "Relies on advanced softwares to ensure unbiased & accurate results.":
          "ﻳﻌﺘﻤﺪ ﻋﻠﻰ ﺑﺮﻣﺠﻴﺎت ﻣﺘﻄﻮرة ﻟﻀﻤﺎن ﺣﻴﺎدﻳﺔ و دﻗﺔ اﻟﻨﺘﺎﺋﺞ.",
        "Clear terms and scored results.": "ﻣﺼﻄﻠﺤﺎت واﺿﺤﺔ و ﻧﺘﺎﺋﺞ ﻣﻘﻴﻤﺔ.",
        Fundementals: "الأساسيات",
        Features: "الميزات",
        inspectionExterior: "اﻟﻬﻴﻜﻞ اﻟﺨﺎرﺟﻲ",
        inspectionInterior: "اﻟﻤﻘﺼﻮرة اﻟﺪاﺧﻠﻴﺔ",
        "Body Paint": "ﻃﻠﺎء اﻟﺒﻮدي",
        "Controls & Switches": "ﻣﻔﺎﺗﻴﺢ و أﻧﻈﻤﺔ اﻟﺘﺤﻜﻢ",
        "Computer Scan": "ﻓﺤﺺ اﻟﻜﻤﺒﻴﻮﺗﺮ",
        "AC/Heater": "اﻟﺘﻜﻴﻴﻒ و اﻟﺘﺪﻓﺌﺔ",
        Tires: "الإطارات",
        Equipment: "المعدات",
        "All the basics you need to know.":
          "ﻛﻞ اﻟﺄﺳﺎﺳﻴﺎت اﻟﺘﻲ ﺗﺤﺘﺎج إﻟﻰ ﻣﻌﺮﻓﺘﻬﺎ.",
        "Make and Model": "اﻟﻨﻮع و اﻟﻤﻮدﻳﻞ",
        Manufacturer: "المصنع",
        "Exterior Color": "اللون الخارجي",
        "What is different about this car?": "ﻣﺎ ﻫﻮ اﻟﻤﺨﺘﻠﻒ ﻓﻲ ﻫﺬه اﻟﻤﺮﻛﺒﺔ؟",
        "Alloy Rims": "ﺟﻨﻄﺎت أﻟﻤﻨﻴﻮم",
        "Central Lock": "ﻗﻔﻞ ﻣﺮﻛﺰي",
        "CD Player": "مشغل CD",
        Airbags: "وﺳﺎﺋﺪ ﻫﻮاﺋﻴﺔ",
        Sunroof: "ﻓﺘﺤﺔ ﺳﻘﻒ",
        ABS: "فرامل مانعة الإنغلاق (ABS)",
        "Any visual issue on the outside?": "أي ملاحظة سطحية ﻣﻦ اﻟﺨﺎرج؟",
        Scratches: "خدوش",
        "Paint Chips": "ﺗﺸﻘﻖ ﻃﻠﺎء",
        Dents: "صدمات",
        Gouges: "نقرات",
        Rust: "صدأ",
        Cracks: "تشققات",
        "Any visual issue on the inside?": "أي ملاحظة سطحية ﻣﻦ اﻟﺪاﺧﻞ؟",
        Discoloration: "بهتان لون",
        Burns: "حروق",
        Holes: "حفرات", // without at
        Stains: "بقع",
        "Any re-painted panels?": "ﻫﻞ ﺗﻮﺟﺪ ﻟﻮﺣﺎت ﻣﺪﻫﻮﻧﺔ؟",
        Roof: "السقف",
        Fenders: "الأجنحة",
        Doors: "الأبواب",
        Hood: "ﻏﻄﺎء اﻟﻤﺤﺮك",
        Pillars: "الشمعات",
        "Any button, switch, control not working?":
          "أي زر ، ﻣﻔﺘﺎح ، ﻧﻄﺎم ﺗﺤﻜﻢ ﻟﺎ ﻳﻌﻤﻞ؟",
        "Window Controls": "ﺗﺤﻜﻢ اﻟﻨﻮاﻓﺬ",
        "Roof Controls": "ﺗﺤﻜﻢ السقف",
        "Door Controls": "ﺗﺤﻜﻢ الأبواب",
        Multimedia: "الميديا",
        "Seat Controls": "ﺗﺤﻜﻢ المقاعد",
        "Any fault codes for mechanical/electrical system?":
          "ﻫﻞ ﻳﻮﺟﺪ رﻣﻮز أﻋﻄﺎل ﻓﻲ اﻟﺄﻧﻈﻤﺔ اﻟﻤﻴﻜﺎﻧﻴﻜﻴﺔ أو اﻟﻜﻬﺮﺑﺎﺋﻴﺔ؟",
        "Airbags Faults": "أعطال الوسائد الهوائية",
        "Transmission Faults": "أعطال ناقل الحركة",
        "Engine Faults": "أعطال المحرك",
        "Battery Faults": "أعطال البطارية",
        "ABS Faults": "أخطاء ABS",
        "We test the AC system too.": "ﻧﻔﺤﺺ ﻧﻈﺎم اﻟﺘﻜﻴﻒ أﻳﻀﺎً.",
        "Air Heating": "ﺗﺤﻜﻢ اﻟﺤﺮارة",
        "Air Throw": "ﺗﺤﻜﻢ اﻟﻬﻮاء",
        "Air Cooling": "ﺗﺤﻜﻢ اﻟتبريد",
        "Are they safe to drive?": "ﻫﻞ اﻟﺎﻃﺎرات آﻣﻨﺔ ﻟﻠﻘﻴﺎدة؟",
        "Tire’s Brand": "الصنع",
        "Visual Issue Inspection": "اﻟﻔﺤﺺ اﻟﻨﻈﺮي",
        "Tire’s Age": "العمر",
        "Remaining Tread Depth": "ﻋﻤﻖ اﻟﻔﺮزة اﻟﻤﺘﺒﻘﻲ",
        "Tire’s Size": "القياس",
        "Any equipment missing?": "ﻫﻞ ﻳﻮﺟﺪ ﻣﻌﺪات ﻣﻔﻘﻮدة؟",
        "Owner’s Manual": "دليل تعليمات المالك",
        "First Aid Kit": "حقيبة الإسعافات الأولية",
        "Number of Keys": "ﻋﺪد اﻟﻤﻔﺎﺗﻴﺢ",
        Jack: "رافعة",
        "Fire Extinguisher": "ﻃﻔﺎﻳﺔ اﻟﺤﺮﻳﻖ",
        "Car licenses are kept confidential and are used to validate transactions within our network.":
          "ﻳﺘﻢ اﻟﺎﺣﺘﻔﺎظ ﺑﺴﺮﻳﺔ رﺧﺺ اﻟﻤﺮﻛﺒﺎت وﻳﺘﻢ اﺳﺘﺨﺪاﻣﻬﺎ ﻟﻠﺘﺤﻘﻖ ﻣﻦ ﺻﺤﺔ اﻟﻤﻌﺎﻣﻠﺎت داﺧﻞ ﺷﺒﻜﺘﻨﺎ. ",
        "Last Step!": "الخطوة الأخيرة!",
        "Select the car you bought below": "اختر المركبة التي اشتريتها ادناه",
        "Upload Car License Image": "تحميل صور رخصة المركبة",
        "Upload both sides of the car license below":
          "قم بتحميل جانبي رخصة السيارة ادناه",
        "Upload Front Side Image": "تحميل الصورة الأمامية",
        "Upload Back Side Image": "تحميل الصورة الخلفية",
        "Thank You for Notifying Us!": "شكراً لإعلامنا!",
        "Our customer service team will contact you as soon as the purchase is confirmed by the dealer.":
          "سيقوم فريق خدمة العملاء لدينا بالتواصل معك بمجرد تأكيد الشراء من التاجر.",
        "You Have No Available Cars in Your History.":
          "لا يوجد لديك أي مركبات متاحة في مواعيدك السابقة.",
        "You can notify purchases on available cars only, or report a problem on a sold car.":
          "تستطيع إعلامنا عن شرائك للسيارت المتاحة فقط, او الإبلاغ عن مشكلة متعلقة بسيارة مباعة.",
        "Back to Appointments History": "العودة إلى المواعيد السابقة",
        "Inspect the easy way, earn money your way.":
          "اﻓﺤﺺ اﻟﻤﺮﻛﺒﺎت و ﺣﻘﻖ دﺧﻠﻚ ﻋﻠﻰ ﻃﺮﻳﻘﺘﻚ ﻣﻊ ﻣﺮﻛﺒﺘﻲ.",
        "Benefits of being a Markabte officer": "ميزات العمل مع مركبتي",
        "How to join us?": "كيفية الانضمام لمركبتي",
        Requirements: "المتطلبات",
        "No knowledge required": "ﻟﺎ ﻳﺘﻄﻠﺐ اﻟﺨﺒﺮة",
        "Guided intelligent app for fast & easy inspection.":
          "ﺗﻄﺒﻴﻖ ذﻛﻲ ﻟﻔﺤﺺ ﺳﺮﻳﻊ و ﺳﻬﻞ.",
        "Flexible Hours": "ﺳﺎﻋﺎت ﻋﻤﻞ ﻣﺮﻧﺔ",
        "Select your own working hours based on your suitable time.":
          "ﺣﺪد ﺳﺎﻋﺎت ﻋﻤﻠﻚ ﺑﻨﺎءً ﻋﻠﻰ اﻟﻮﻗﺖ اﻟﻤﻨﺎﺳﺐ ﻟﻚ.",
        "Great Income": "دﺧﻞ ﻣﻤﺘﺎز",
        "Fixed rate on each car inspection, commission on each car sale.":
          "ﻣﺒﻠﻎ ﺛﺎﺑﺖ ﻟﻜﻞ ﻣﺮﻛﺒﺔ ﺗﻔﺤﺺ, ﻋﻤﻮﻟﺔ ﻟﻜﻞ ﻣﺮﻛﺒﺔ ﺗﺒﺎع.",
        Apply: "ﻗﺪم ﻃﻠﺐ",
        "Fill out the form and apply at the top of the page.":
          "اﻣﻠﺄ اﻟﻨﻤﻮذج و ﻗﺪم ﻃﻠﺐ أﻋﻠﺎه.",
        Interview: "مقابلة",
        "You will be invited for an interview after your application has been considered.":
          "ﺳﺘﺘﻢ دﻋﻮﺗﻚ ﻟﺈﺟﺮاء ﻣﻘﺎﺑﻠﺔ ﺑﻌﺪ اﻟﻨﻈﺮ ﻓﻲ ﻃﻠﺒﻚ.",
        Onboarding: "تدريب",
        "Get your training and be ready to work with us.":
          "اﻧﻬﻲ ﻋﻠﻰ ﺗﺪرﻳﺒﻚ و ﻛﻦ ﺟﺎﻫﺰً اﻟﻠﻌﻤﻞ ﻣﻌﻨﺎ.",
        Age: "العمر",
        "You have to be over 18 years of age.": "ﻳﺠﺐ أن ﺗﻜﻮن 18 ﺳﻨﺔ ﻓﻤﺎ ﻓﻮق.",
        Smartphone: "ﻫﺎﺗﻒ ﻣﺤﻤﻮل",
        "You will need an Android device with internet connection to receive and list the requests.":
          "ﺳﺘﺤﺘﺎج إﻟﻰ ﺟﻬﺎز اﻧﺪروﻳﺪ ﻟﺘﻠﻘﻲ اﻟﻄﻠﺒﺎت وإدراﺟﻬﺎ.",
        Car: "السيارة",
        "You need to have a car with a valid driver’s license.":
          "ﺗﺤﺘﺎج إﻟﻰ ﺳﻴﺎرة ﺑﺮﺧﺼﺔ ﻗﻴﺎدة ﺳﺎرﻳﺔ.",
        Screening: "اﻟﺘﺤﻘﻖ اﻟﺄﻣﻨﻲ",
        "The screening process must include issuing a Non-Criminal Certificate.":
          "ﻋﻤﻠﺒﺔ اﻟﺘﺤﻘﻖ اﻟﺄﻣﻨﻲ ﺗﺘﻀﻤﻦ إﺻﺪار ﺷﻬﺎدة ﻋﺪم ﻣﺤﻜﻮﻣﻴﺔ.",
        "Become an officer now!": "انضم كمندوب!",
        "Apply Now": "انضم الآن",
        "This phone already has a request": "هذا الهاتف لديه طلب",
        "Your request has been submitted successfully": "تم تقديم طلبك بنجاح",
        "Dedicated officers at your fingertips, Qualified buyers to your dealership.":
          "ﺿﺒﺎط ﻣﺘﺄﻫﺒﻮن ﻓﻲ ﺧﺪﻣﺘﻚ زﺑﺎﺋﻦ ﻣﺆﻫﻠﻴﻦ ﻓﻲ ﻣﻌﺮﺿﻚ",

        "Benefits of being a Markabte partner": "ميزات الانضمام لمركبتي كشريك",
        "Serious Buyers": "ﻣﺸﺘﺮﻳﻦ ﺟﺎدﻳﻦ",
        "Reach a higher number of serious buyers to your dealership.":
          ".ﻗﻢ ﺑﺎﻟﻮﺻﻮل إﻟﻰ ﻋﺪد أﻛﺒﺮ ﻣﻦ اﻟﻤﺸﺘﺮﻳﻦ اﻟﺠﺎدﻳﻴﻦ اﻟﻰ ﻣﻌﺮﺿﻚ",
        "Dedicated Workforce": "ﻛﺎدر ﻣﺨﺼﺺ",
        "Don’t worry about a thing, we handle everything.":
          ".ﺳﻨﻘﻮم ﺑﻔﻌﻞ ﻛﻞ ﺷﻲ , ﻟﺎ ﺗﻘﻠﻖ ﻣﻦ اي ﺷﻲء",
        "Risk-Free": "ﺑﻠﺎ ﻣﺨﺎﻃﺮ",
        "No Subscriptions! Pay on successful car sale only.":
          " ﻟﻦ ﺗﻘﻮم ﺑﺎﻟﺪﻓﻊ اﻟﺎ ﻋﻨﺪ ﻋﻤﻠﻴﺎت اﻟﺒﻴﻊ اﻟﻨﺎﺟﺤﺔ",
        Apply: "ﻗﺪم ﻃﻠﺐ",
        "Fill out the form and apply at the top of the page.":
          ".اﻣﻠﺄ اﻟﻨﻤﻮذج و ﻗﺪم ﻃﻠﺐ أﻋﻠﺎه",
        "Sign The Contract": "وﻗﻊ اﻟﻌﻘﺪ",
        "Our sales team will visit you after your application has been considered.":
          ".ﺳﻴﻘﻮم ﻓﺮﻳﻖ اﻟﻤﺒﻴﻌﺎت ﻟﺪﻳﻨﺎ ﺑﺰﻳﺎرﺗﻚ ﺑﻌﺪ اﻟﻨﻈﺮ ﻓﻲ ﻃﻠﺒﻚ ",
        "Sell Cars!": "!ﻗﻢ ﺑﺒﻴﻊ اﻟﻤﺮﻛﺒﺎت",
        "Meet our serious buyers at your dealership and close the deal.":
          ".ﻗﺎﺑﻞ اﻟﻤﺸﺘﺮﻳﻦ اﻟﺠﺎدﻳﻦ ﻟﺪﻳﻨﺎ ﻓﻲ ﻣﻌﺮﺿﻚ وأﻏﻠﻖ اﻟﺼﻔﻘﺔ",
        "Join Us for Free!": "انضم لنا مجاناً!",
        DealershipName: "إسم المعرض",
        "Join Now": "انضم الان",
        city: "المدينة",
        Terms: "شروط",
        and: "و",
        "join now": "انضم الأن",
        Amman: "عمان",
        Aqaba: "العقبة",
        Jerash: "جرش",
        Irbid: "إربد",
        Madaba: "مادبا",
        Zarqa: "الزرقاء",
        Kerak: "الكرك",
        "al-mafraq": "المفرق",
        Ajloun: "عجلون",
        "as-salt": "السلط",
        "Ma'an": "معان",
        "At-Tafilah": "الطفيلة",
        "Thank you for applying to us!": "اﻧﺖ ﻋﻠﻰ وﺷﻚ اﻟﺈﻧﻀﻤﺎم اﻟﻴﻨﺎ!",
        "Our team will contact you as soon as possible.":
          "ﺳﻴﻘﻮم ﻓﺮﻳﻘﻨﺎ ﺑﺎﻟﺘﻮاﺻﻞ ﻣﻌﻚ ﺑﺄﻗﺮب وﻗﺖ ﻣﻤﻜﻦ.",
        "Go to Homepage": "اﻟﺼﻔﺤﺔ اﻟﺮﺋﻴﺴﻴﺔ",
        "Coming soon": "قريباً",
        "We are currently working on it!": "ﻧﻘﻮم ﺑﺎﻟﻌﻤﻞ ﻋﻠﻴﻪ ﻓﻲ اﻟﻮﻗﺖ اﻟﺤﺎﻟﻲ!",
        "Oops!": "عذراً!",
        "Something went wrong, please try again later.":
          "حدث خطأ، يرجى المحاولة فى وقت لاحق.",
        "Copy Link": "نسخ الرابط",
        "Share Car Listing": "نشر المركبة",
        copied: "تم النسخ",
        " yr. ": "سنة ",
        " mos": "شهر ",
        closed: "مغلق",
        Moderate: "متوسط",
        Poor: "ضعيف",
        "View Image": "رؤﻳﺔ اﻟﺼﻮرة",
        "A measure that reflects the general condition of battery & it’s ability to deliver needed performance ccompared to  new battery.":
          "ﻣﺆﺷﺮ ﻳﻌﻜﺲ ﺣﺎﻟﺔ اﻟﺒﻄﺎرﻳﺔ اﻟﻌﺎﻣﺔ و ﻗﺪرﺗﻬﺎ ﻋﻠﻰ ﺗﻘﺪﻳﻢ اﻟﺄداء اﻟﻤﻄﻠﻮب ﻣﻘﺎرﻧﺔ ﺑﺒﻄﺎرﻳﺔ ﺟﺪﻳﺪة.",
        "We estimate the battery’s remaining life by comparing its current capacity to its ideal capacity when new":
          "ﻳﻘﺪر اﻟﻌﻤﺮ اﻟﻤﺘﺒﻘﻲ ﻟﻠﺒﻄﺎرﻳﺔ ﻣﻦ ﺧﻠﺎل ﻣﻘﺎرﻧﺔ ﺳﻌﺘﻬﺎ اﻟﺤﺎﻟﻴﺔ ﺑﺴﻌﺘﻬﺎ اﻟﻤﺜﺎﻟﻴﺔ ﻋﻨﺪﻣﺎ ﺗﻜﻮن ﺟﺪﻳﺪة",
        "Faults | ": "أعطال | ",
        Check: "شاهد",
        "Checkout this car on markabte": "شاهد هذا الاعلان على مركبتي",
        "Markabte Search for Pre-inspected cars for free  on Markabte":
          "مركبتي ابحث عن السيارات المفحوصة مسبقاًً مجاناً على مركبتي",
        Previous: "السابق",
        "Click to view issues": "أنقر لمعاينة الملاحظات",
        "* We do not record any issue in the body that is less than 0.2 (cm).":
          "* لا نسجل أي ضرر على هيكل المركبة لا يتجاوز 0.2 (سم) بالمساحة.",
        "* In some cases, Re-painted panels cannot be detected.":
          "* في بعض الحالات لا يمكن اكتشاف الأجزاء المدهونة بالمركبة.",
        "* We do not record any scratch that cannot be felt by hand.":
          "* لا نسجل أي خدش لا يمكن الإحساس به باليد.",
        "* We do not inspect whether the battery was replaced or repaired.":
          "* لا نتحقق من تبديل البطارية أو إصلاحها.",
        "* We do not check if the battery was replaced or repaired.":
          "* لا نتحقق من تبديل البطارية أو إصلاحها.",
        "* We do not inspect the inside of the tire or the internal sidewall of the tire.":
          "* لا نفحص الإطار من الداخل أو الجزء الداخلي من الحائط الجانبي للإطار.",
        "* We do not confirm the genuineness of the equipment.":
          "* لا نحدد إذا كانت المعدات اصلية.",
        "* We dont record fault codes that are saved in the car’s computer but are inactive (Repaired).":
          "* لا نسجل رموز الأعطال التي تم حفظها في كمبيوتر المركبة و لكنها غير فعالة (تم إصلاحها).",
        "* We do not validate the results of an inspection report from any third party.":
          "* لا نتحقق من صحة نتائج أي فحص من جهة أخرى.",
        "No Notes": "ﻟﺎ ﻣﻠﺎﺣﻈﺎت",
        Notes: "ملاحظات",
        "Replace Battery": "ﺗﺒﺪﻳﻞ اﻟﺒﻄﺎرﻳﺔ",
        "Not Inspected due to influence of resistance values":
          "ﻟﻢ ﻳﺘﻢ اﻟﻔﺤﺺ ﺑﺴﺒﺐ ﺗﺄﺛﺮ ﻗﻴﻢ اﻟﻤﻘﺎوﻣﺔ",
        "Unlock Info (1 Point)": "كشف المعلومات (1 نقطة)",
        /////////////////////////////////////////// check these
        "Inspected Point": "نقطة فحص",
        "Inspection scores are locked.": "نتائج الفحص محجوﺑﺔ.",
        Points: "نقطة",
        "Inspection Details are Locked.": "تفاصيل الفحص محجوﺑﺔ.",
        "Check out a sample of our inspection report for different cars.":
          "اﻃﻠﻊ ﻋﻠﻰ ﻋﻴﻨﺔ ﻣﻦ ﺗﻘﺮﻳﺮ ﻓﺤﺺ ﻣﺮﻛﺒﺘﻲ ﻟﻤﺮﻛﺒﺎت ﻣﺨﺘﻠﻔﺔ.",
        "Report Samples>": "نماذج فحص>",
        ///////////////////////////////////////////

        "What We Inspect?": "ماذا نفحص؟",
        "Want to know more?": "تريد معرفة المزيد؟",
        "Go to inspection process >": "إذهب لنموذج الفحص",
        "See For Yourself!": "انظر بنفسك!",
        "Check out a sample of our inspection":
          "قم بمراجعة نسخة من الفحص الخاص بنا",
        "report for different cars.": "لسيارات مختلفة.",
        "Report Samples>": "نسخة من الفحص>",
        "Check out a sample of our inspection report":
          "قم بمراجعة نسخة من الفحص الخاص بنا",
        "for different cars.": "لسيارات مختلفة.",
        "How It Works?": "كيف تعمل مركبتي؟",
        "Inspection Centers": "مراكز الفحص",
        "Car Websites": "مواقع السيارات",
        "Pre-Inspected Vehicles": "سيارات تم فحصها مسبقاً",
        "All of Markabte’s cars are inspected by professionals.":
          "يتم فحص جميع سيارات مركبتي من قبل محترفين.",
        "Online Detailed Inspection Report": "تقرير فحص مفصل عبر الانترنت",
        "Our inspection is fully digitized, avaialble upfront.":
          "فحص مركبتي رقمي بالكامل, ومتوفر مقدماً.",
        "Trust and Transparency": "الثقة والشفافية",
        "Touring Dealership": "الذهاب للمعرض",
        "No need to waste time touring, browse variety of cars.":
          "لا داعي لإضاعة الوقت في التجول ، وتصفح مجموعة متنوعة من السيارات.",
        "Dedicated Advisor": "مستشار خاص",
        "Markabte Advisor will be at your service anytime.":
          "سيكون مستشار مركبتي في خدمتك في أي وقت.",
        Markabte: "مركبتي",
        ////////////
        "Text Seller": "ﺗﻮاﺻﻞ ﻣﻌﻨﺎ",
        "Call Us": "اﺗﺼﻞ ﺑﻨﺎ",
        "Call Seller": "ﺗﻮاﺻﻞ ﻣﻊ اﻟﺒﺎﺋﻊ",
        "Chat With Us": "اﺗﺼﻞ ﻣﻊ اﻟﺒﺎﺋﻊ",
        "Are you sure you want to request history report for":
          "ﻫﻞ أﻧﺖ ﻣﺘﺄﻛﺪ ﻣﻦ ﻃﻠﺐ ﺗﻘﺮﻳﺮ ﻛﺎرﺳﻴﺮ ﻟﻠﻤﺮﻛﺒﺔ",
        "Select the car you want": "اختر السيارة",
        "Request Carseer History Report": "ﻃﻠﺐ ﺗﻘﺮﻳﺮ ﻛﺎرﺳﻴﺮ",
        "Request History Report": "اﻃﻠﺐ ﺗﻘﺮﻳﺮ ﻛﺎرﺳﻴﺮ",
        "1 Carseer History Report": "1 ﺗﻘﺮﻳﺮ ﻛﺎرﺳﻴﺮ",
        "Markabte Advisor": "ﻣﺴﺘﺸﺎر ﻣﺮﻛﺒﺘﻲ",
        Car: "ﻣﺮﻛﺒﺎت",
        Credits: "رﺻﻴﺪ",
        Subscribe: "اﺷﺘﺮك",
        Upgrade: "ﺗﺤﺪﻳﺚ",
        advisor: "advisor",
        "Get in Touch": "تواصل",
        "Request Report": "اﻃﻠﺐ اﻟﺘﻘﺮﻳﺮ",
        Cars: "سيارات",
        "My Cars": " مركباتي",
        "Markabte Advisor Requested": "تم طلب مستشار مركبتي",
        "Markabte Advisor will contact you soon.":
          "سيتواصل مستشار مركبتي معك في اقرب وقت.",
        "Wrong Number": "رقم الهاتف خاطئ",
        "Update Number": "عدل (او حدث) رقم الهاتف",
        "History Report Requested": "تم طلب التقرير",
        "Our customer support team will contact you soon.":
          "سيتواصل فريق الخدمة معك قريباَ",
        Exp: "صالحة",
        days: "أيام",
        months: "أشهر",
        expired: "انتهت صلاحية الاشتراك",
        "Markabte Inspection Sample Report": "نسخة عن فحص مركبتي",
        "Select a car to view full inspection report":
          "حدد سيارة لعرض تقرير الفحص الكامل",
        "No Additional Services": "ﻟﺎ ﻳﻮﺟﺪ ﺧﺪﻣﺎت إﺿﺎﻓﻴﺔ",
        "Car Credits": "رصيد المركبات",
        "Remaining balance after use": "الرصيد المتبقي بعد الاستخدام",
        Plan: "اﻟﺒﺎﻗﺔ",

        "*As low as": "*اﺑﺘﺪاء ﻣﻦ",
        "*Only for": "*ﻓﻘﻂ ب",
        ContinueCharge: "التالي",
        "Choose a method that fits your needs":
          "اﺧﺘﺮ اﻟﻄﺮﻳﻘﺔ اﻟﺘﻲ ﺗﻨﺎﺳﺐ اﺣﺘﻴﺎﺟﺎﺗﻚ",
        "/Car": "للمركبة",
        "Charge Car Credits": "ﺷﺤﻦ رﺻﻴﺪ اﻟﻤﺮﻛﺒﺎت",
        "Subscription Plans": "ﺑﺎﻗﺎت اﻟﺎﺷﺘﺮاك",
        "Unlock cars and access valuable benefits.":
          "اﺣﺼﻞ ﻋﻠﻰ ﺟﻤﻴﻊ اﻟﻤﻌﻠﻮﻣﺎت ﻟﻠﻤﺮﻛﺒﺔ و ﻣﺰاﻳﺎ ﻗﻴﻤﺔ",
        "Are you sure you want to unlock inspection details and seller’s information for this car?":
          "ﻫﻞ أﻧﺖ ﻣﺘﺄﻛﺪ أﻧﻚ ﺗﺮﻳﺪ ﻛﺸﻒ ﺗﻘﺮﻳﺮ اﻟﻔﺤﺺ و  ﻣﻌﻠﻮﻣﺎت اﻟﺒﺎﺋﻊ ﻟﻬﺬه اﻟﻤﺮﻛﺒﺔ؟",
        "Use your Credits to unlock inspection report and more.":
          " اﺳﺘﺨﺪم رﺻﻴﺪك ﻟﻠﺤﺼﻮل ﻋﻠﻰ ﺗﻘﺮﻳﺮ اﻟﻔﺤﺺ, ﻣﻌﻠﻮﻣﺎت اﻟﺒﺎﺋﻊ  و ﻏﻴﺮه.",
        "Interested in This Car?": "ﻣﻬﺘﻢ ﺑﻬﺬه السيارة؟",
        "Unlock Car": "ﻛﺸﻒ اﻟﻤﺮﻛﺒﺔ",
        "Charge Credits": "ﺷﺤﻦ اﻟﺮﺻﻴﺪ",
        Unlocked: "مكشوفة",
        "Go to Sample Reports": "اذﻫﺐ إﻟﻰ ﻋﻴﻨﺎت اﻟﻔﺤﺺ",
        "How We Compare?": "ﻛﻴﻒ ﻧﻘﺎرن؟",
        "Detailed and comprehensive inspection report available with every car":
          "ﺗﻘﺮﻳﺮ ﻓﺤﺺ ﺷﺎﻣﻞ وﺗﻔﺼﻴﻠﻲ ﻣﺘﻮﻓﺮ ﻣﻊ ﻛﻞ ﻣﺮﻛﺒﺔ",
        "High Quality Content": "ﻣﺤﺘﻮى ﻋﺎﻟﻲ اﻟﺠﻮدة",
        "Standardized car information without fake or duplicate listings":
          "ﻣﻌﻠﻮﻣﺎت ﻣﻮﺣﺪة ﻋﻦ اﻟﺴﻴﺎرات دون أي إﻋﻠﺎﻧﺎت ﻣﺰﻳﻔﺔ أو ﻣﻜﺮرة",
        "Unbiased Information": "ﺣﻴﺎدﻳﺔ اﻟﻤﻌﻠﻮﻣﺎت",
        "All details are not posted by the seller":
          "ﺟﻤﻴﻊ اﻟﺘﻔﺎﺻﻴﻞ ﻟﺎ ﻳﺘﻢ ﻧﺸﺮﻫﺎ ﻣﻦ ﻗﺒﻞ اﻟﺒﺎﺋﻊ",
        "Advisor available to provide any assistance or answer any questions in your journey to purchase a car":
          "ﻣﺴﺘﺸﺎر ﻣﺘﺎح ﻟﺘﻘﺪﻳﻢ أي ﻣﺴﺎﻋﺪة أو اﻟﺈﺟﺎﺑﺔ ﻋﻠﻰ أي أﺳﺌﻠﺔ ﻓﻲ رﺣﻠﺘﻚ ﻟﺸﺮاء ﻣﺮﻛﺒﺔ",
        "No Ads": "ﻟﺎ إﻋﻠﺎﻧﺎت",
        "No irrelevant ads, just cars": "ﻟﺎ إﻋﻠﺎﻧﺎت دون ﺻﻠﺔ, ﻓﻘﻂ ﻣﺮﻛﺒﺎت",
        "Best Prices": "أﻓﻀﻞ اﻟﺄﺳﻌﺎر",
        "Pay less & get more": "ادﻓﻊ أﻗﻞ و اﺣﺼﻞ ﻋﻠﻰ اﻟﻤﺰﻳﺪ",
        "Available Upfront & Online": "ﻣﺘﻮﻓﺮ ﻣﺴﺒﻘﺎً و أوﻧﻠﺎﻳﻦ",
        "Know the car’s condition instantly & from your home":
          "اﻋﺮف ﺣﺎﻟﺔ اﻟﻤﺮﻛﺒﺔ ﻓﻮراً و ﻣﻦ ﻣﻨﺰﻟﻚ",
        "Inspection Automation Policy": "ﺳﻴﺎﺳﺔ اﻟﻔﺤﺺ اﻟﺂﻟﻲ",
        "Advanced inspection technology and processes to reduce human interference and human error":
          "ﺗﻜﻨﻮﻟﻮﺟﻴﺎ و أﺳﺎﻟﻴﺐ ﻓﺤﺺ ﻣﺘﻄﻮرة ﻟﻠﺤﺪ ﻣﻦ اﻟﺘﺪﺧﻞ اﻟﺒﺸﺮي و اﻟﺄﺧﻄﺎء اﻟﺒﺸﺮﻳﺔ",
        "Visual Condition Report": "ﺗﻘﺮﻳﺮ اﻟﺄﺿﺮار اﻟﻨﻈﺮي",
        "Interactive visual report of cosmetic damages from the car’s exterior and interior":
          "ﺗﻘﺮﻳﺮ ﻧﻈﺮي ﺗﻔﺎﻋﻠﻲ ﻟﻠﺄﺿﺮار اﻟﺨﺎرﺟﻴﺔ ﻣﻦ داﺧﻞ و ﺧﺎرج اﻟﻤﺮﻛﺒﺔ",
        "Buyer Friendly Report": "ﺗﻘﺮﻳﺮ ﻣﺨﺼﺺ ﻟﻠﻤﺸﺘﺮي",
        "Clear terms and scored results organized in an easy format":
          "ﻣﺼﻄﻠﺤﺎت واﺿﺤﺔ و ﻧﺘﺎﺋﺞ ﻣﻘﻴﻤﺔ ﻣﻨﻈﻤﺔ ﺑﺸﻜﻞ ﺳﻬﻞ",
        "Find Your Suitable Car": "اﺑﺤﺚ ﻋﻦ اﻟﻤﺮﻛﺒﺔ اﻟﻤﻨﺎﺳﺒﺔ ﻟﻚ",
        "Browse different cars and find the one that fits your needs.":
          "ﺗﺼﻔﺢ اﻟﻤﺮﻛﺒﺎت اﻟﻤﺨﺘﻠﻔﺔ و اﺧﺘﺎر اﻟﻤﺮﻛﺒﺔ اﻟﺘﻲ ﺗﻠﺒﻲ اﺣﺘﻴﺎﺟﺎﺗﻚ.",
        "Unlock Inspection Details": "اﻛﺸﻒ ﺗﻔﺎﺻﻴﻞ اﻟﻔﺤﺺ",
        "Subscribe and know the true car condition upfront.":
          "اﺷﺘﺮك و اﻋﺮف ﺣﺎﻟﺔ اﻟﻤﺮﻛﺒﺔ اﻟﺤﻘﻴﻘﻴﺔ ﻣﺴﺒﻘﺎً.",
        "Contact Seller & Buy Your Car": "ﺗﻮاﺻﻞ ﻣﻊ اﻟﺒﺎﺋﻊ و اﺷﺘﺮي ﻣﺮﻛﺒﺘﻚ",
        "Meet the seller and seal the deal.": "ﻗﺎﺑﻞ اﻟﺒﺎﺋﻊ و اﺧﺘﻢ اﻟﺼﻔﻘﺔ.",
        "Plan upgraded successfully.": "ﺗﻢ ﺗﺤﺪﻳﺚ اﻟﺒﺎﻗﺔ ﺑﻨﺠﺎح.",
        "Upgrade Plan": "ﺗﺤﺪﻳﺚ اﻟﺒﺎﻗﺔ",
        "Choose the number of cars below": "اﺧﺘﺮ ﻋﺪد اﻟﻤﺮﻛﺒﺎت ادﻧﺎه",
        "Re-fill Credits": "إﻋﺎدة ﺗﻌﺒﺌﺔ رﺻﻴﺪ",
        "One Time Purchase": "اﻟﺪﻓﻊ ﻣﺮة واﺣﺪة",
        "Unlock one car, no strings attached.":
          "اﺣﺼﻞ ﻋﻠﻰ ﺟﻤﻴﻊ اﻟﻤﻌﻠﻮﻣﺎت ﻟﻤﺮﻛﺒﺔ واﺣﺪة ﻓﻘﻂ.",
        "Plan credits depleted? Re-fill your credits at the lowest price of":
          "اﺳﺘﻨﻔﺪت رﺻﻴﺪ اﻟﺒﺎﻗﺔ؟ أﻋﺪ ﺗﻌﺒﺌﺔ رﺻﻴﺪك ﺑﺄﻗﻞ ﺳﻌﺮ",
        "Continue to Payment": "ﺗﺎﺑﻊ إﻟﻰ اﻟﺪﻓﻊ",
        "Total Subscription Price": "إﺟﻤﺎﻟﻲ ﺳﻌﺮ اﻟﺎﺷﺘﺮاك",
        "Still unsure? Get 1 free Carseer history report of a car of your choice.":
          " ﻏﻴﺮ ﻣﺘﺄﻛﺪ؟ اﺣﺼﻞ ﻋﻠﻰ ﺗﻘﺮﻳﺮ ﻛﺎرﺳﻴﺮ ﻟﻤﺮﻛﺒﺔ ﻣﻦ اﺧﺘﻴﺎرك.",
        "1 Free History Report": "ﺗﻘﺮﻳﺮ ﻛﺎرﺳﻴﺮ ﻣﺠﺎﻧﻲ ﻟﻤﺮﻛﺒﺔ واﺣﺪة",
        "Feeling lost? Our advisors will help you out with anything.":
          ".ﻣﺴﺘﺸﺎرﻳﻦ ﻣﺮﻛﺒﺘﻲ ﻣﺴﺘﻌﺪون ﻟﻤﺴﺎﻋﺪﺗﻚ ﺑﺄي ﺷﻲء",
        "Re-fill at same price": "إﻋﺎدة ﺗﻌﺒﺌﺔ رﺻﻴﺪ ﺑﻨﻔﺲ اﻟﺴﻌﺮ",
        "Markabte Subscription Plans": "ﺑﺎﻗﺎت اﻟﺎﺷﺘﺮاك",
        "Choose subscription plan that fits your needs.":
          "اﺧﺘﺮ ﺑﺎﻗﺔ اﻟﺎﺷﺘﺮاك اﻟﺘﻲ ﺗﻨﺎﺳﺐ اﺣﺘﻴﺎﺟﺎﺗﻚ:",
        "You can access seller’s informtion and location from your garage.":
          "يمكنك الوصول إلى معلومات البائع والموقع من كراجي.",
        "Seller’s Information": "معلومات البائع",
        JDS: "د.أ",
        "Per car": "للمركبة",
        "JD/Car": "د.أ ﻟﻠﻤﺮﻛﺒﺔ",
        "Price/Car": "اﻟﺴﻌﺮ/ﻣﺮﻛﺒﺔ",
        "plan successfully!": "بنجاح!",
        "Car Unlocked Successfully!": "تم كشف المركبة بنجاح!", // here rev
        "1 car credit has been added to your balance.":
          "ﺗﻢ إﺿﺎﻓﺔ 1 رﺻﻴﺪ ﻣﺮﻛﺒﺎت إﻟﻰ ﺣﺴﺎﺑﻚ.",
        "Inspection Details are Locked.": "نتائج الفحص مقفلة.",
        "Check out a sample of our inspection report for different cars.":
          "اطلع على عينة من تقرير فحص مركبتي لمركبات مختلفة.",
        "View Report Samples": "عينات عن التقرير",
        "Unlocked Cars": "سيارات مكشوفة",
        "Subscribed to": "ﺗﻢ اﻟﺎﺷﺘﺮاك ﺑﺒﺎﻗﺔ",
        Benefits: "اﻟﻤﺰاﻳﺎ",
        "Number of Cars": "ﻋﺪد اﻟﻤﺮﻛﺒﺎت",
        "Inspection Unlocked": "فحص مكشوف",
        // Unlocked: "",
        "Not enough credits": "اﻟرصيد غير كافي",
        "Account Created Successfully!": "تم إنشاء الحساب بنجاح!",
        "You have 1 free point in your balance to access any car of your choice.":
          "لديك 1 رصيد للمركبات في حسابك لكشف أي مركبة من اختيارك.",
        Continue: "إستمرار",
        "Re-painted": "أعيد طلاؤها",
        "Original Paint": "الطلاء الأصلي",
        "Not measured": "لم يتم قياسها",
        "Limited Offer": "لمدة محدودة",
        "Free Inspection Credit for New Users.":
          "رصيد فحص مجاني للمشتركين الجدد.",
        "Sign Up Now!": "أنشئ حساب الآن!",
        "Free Inspection Credit": "رصيد فحص مجاني",
        "for New Users.": "للمشتركين الجدد.",
        "You are Now a Markabte User!": "انت الآن مستخدم مركبتي !",
        "Use your free credit to unlock any car inspection report of your choice.":
          "استخدم رصيدك المجاني لكشف أي فحص ﻷي مركبة من إختيارك.",
        "Free Plan": "الباقة المجانية",
        "Car Views": "ﺟﻬﺎت اﻟﻤﺮﻛﺒﺔ",
        "Click on the views to check the car's condition from a specific view.":
          "اضغط على الأزرار  لتفقد حالة المركبة من جهة معينة",
        "You can view all the issues of the selected car view here.":
          "ﻳﻤﻜﻨﻚ اﻟﺈﻃﻠﺎع ﻋﻠﻰ ﺟﻤﻴﻊ اﻟﺄﺿﺮار ﻟﻠﺠﻬﺔ اﻟﻤﺨﺘﺎرة ﻫﻨﺎ.",
        "issues list": "ﻗﺎﺋﻤﺔ اﻟﺄﺿﺮار",
        "Click on the hotspots to see an up-close image of the damage.":
          "اﺿﻐﻂ ﻋﻠﻰ اﻟﻌﻠﺎﻣﺎت اﻟﻤﻠﻮﻧﺔ ﻟﺮؤﻳﺔ ﺻﻮرة اﻟﻀﺮرﻋﻦ ﻗﺮب.",
        "Damage Areas": "ﻣﻨﺎﻃﻖ اﻟﻀﺮر",
        Next: "التالي",
        "Click here for help": "اﺿﻐﻂ ﻫﻨﺎ ﻟﻠﻤﺴﺎﻋﺪة",
        ////////////////////
        "Terms & Conditions": "Terms & Conditions",
        "Acceptance of Terms & Conditions": "Acceptance of Terms & Conditions",
        AcceptanceFirstParagraphFirstHalf:
          "The following terms and conditions govern all use of the",
        AcceptanceFirstParagraphSecondtHalf:
          "website and all content, services and products available at or through the website. The Website (“Markabte.com”) is owned and operated by Advanced Markabte for Digital Marketing LL.C. (“Markabte”). The Website is offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies and procedures that may be published from time to time on this website by Markabte (collectively referred to “Agreement”).",
        AcceptanceSecondParagraph:
          "You are advised to carefully read the Agreement prior accessing or using the Website or any of its features. By accessing or using any part of the website, you accept that this agreement is legally valid and binding.  Should you not accept any of the Terms or Conditions contained herein this agreement, then you shall immediately leave the Website. If these terms and conditions are considered an offer by Markabte, acceptance is expressly limited to these terms.",
        "I. Age restriction": "I. Age restriction",
        AgeParagraph:
          "You must be at least 18 (eighteen) years of age or reach the age of majority in the country of your residence prior your use to the website. By using this website, you warrant that you are at least 18 years of age and you may legally adhere to this Agreement. Markabte assumes no responsibility for liabilities related to age misrepresentation.",
        "II. User Account": "II. User Account",
        UserAccountFirstParagraph:
          "If you create a user account to access the services of this Website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account including but not limited to the content you add to your account on the Website and any unlawful or illegal activities carried using your account. ",
        UserAccountSecondParagraph:
          "Therefore, you understand and accept not to share your user account or password with any person or a third-party, where you will solely be liable for any harm or damages resulting from any illegal, unlawful and harmful acts carried by your user account during your activity on the Website. ",
        UserAccountThirdParagraph:
          "If you become aware or suspect that there are any possible issues regarding the security of your account on the website, you shall immediately inform us and change your password. ",
        UserAccountFourthParagraph:
          "We reserve all rights to terminate accounts, edit or remove content at our sole discretion.",
        "III. Responsibility of Users": "III. Responsibility of Users",
        UserResponsibilityParagraph:
          "If you post material to the Website, post links on the Website, or otherwise make (or allow any third party to make) material available by means of the Website (any such material, “Content”), You are entirely responsible for the content of and any harm resulting from that Content regardless whether the Content consists of text, graphics, audio, or software. By making Content available, you represent and warrant that:",
        UserResponsibilityFirstListItem:
          "the downloading, copying and use of the Content will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark or trade secret rights, of any third party.",
        UserResponsibilitySecondListItem:
          "you have fully complied with any third-party licenses relating to the Content, and took all necessary measures to satisfy the terms and conditions of the third party.",
        UserResponsibilityThirdListItem:
          "the Content does not contain or install any viruses, worms, malware, Trojan horses or other harmful or destructive content;",
        UserResponsibilityFourthListItem:
          "the Content is not spam, is not machine- or randomly-generated, and does not contain unethical or unwanted commercial content designed to drive traffic to third party sites or boost the search engine rankings of third party sites, or to further unlawful acts (such as phishing);",
        UserResponsibilityFifthListItem:
          "the Content shall not conflict with the Taboo of Country of Operation or include any Pornographic and/or Nudity or any Inappropriate or Illegal content.",
        UserResponsibilitySixthListItem:
          "By submitting Content to Markabte for inclusion on your account, you grant Markabte a world-wide, royalty-free, and non-exclusive license to reproduce, modify, adapt and publish the Content solely for the purpose of displaying, distributing and promoting your account. If you delete Content, Markabte will use reasonable efforts to remove it from the Website, but you acknowledge that caching or references to the Content may not be made immediately unavailable.",
        UserResponsibilitySeventhListItem:
          "Without limiting any of those representations or warranties, Markabte has the right at its sole discretion to refuse or remove any content that, in Markabte’s reasonable opinion, violates any Markabte policy or is in any way harmful.",
        "IV. Payment": "IV. Payment",
        "V. Restricted Use": "V. Restricted Use",
        "VI. Responsibility for Using the Website":
          "VI. Responsibility for Using the Website",
        "VII. Copyright": "VII. Copyright",
        "VIII. Intellectual Property": "VIII. Intellectual Property",
        "IX. Advertisements": "IX. Advertisements",
        "X. Alterations": "X. Alterations",
        "XI. Termination": "XI. Termination",
        "XII. Cancelation & Expiration": "XII. Cancelation & Expiration",
        "XIII. Refund": "XIII. Refund",
        "XIV. Indemnification": "XIV. Indemnification",
        PaymentFirstParagraph:
          "By subscribing to any plans on the Website you agree to processing your payment through a third-party payment gateway. Processing your payment may be subject to additional fees for currency conversion rates or any other fees imposed by your bank.",
        PaymentSecondParagraph:
          "You will be entitled to purchase the following through our website:",
        PaymentFirstListItem:
          "Car Services: To receive a technical support and guidance by Expertise Markabte Team.",
        PaymentSecondListItem:
          "Car Credits: To unlock any inspection report(s).",
        RestUseParagraph:
          "Markbate may terminate or deny your access to use the Website for any reason without a prior notice in the event of violating any of the policies or breaching any of the terms of this Agreement. Should your access to the website is revoked you will not be entitled to refund any amounts paid under your account for purchasing any plans.",
        RespUseParagraph:
          " You are responsible for taking precautions as necessary to protect yourself and your device from viruses, worms, Trojan horses, and other harmful or destructive content. The Website may contain some technical inaccuracies, typographical mistakes, and other errors. Markabte disclaims any liability for any harm resulting from the use of the Website, or from any content you may download.",
        CopyRightParagraph:
          " Markabte will terminate your access to use the Website if, under appropriate circumstances, it is determined that you have infringed the copyrights or any other intellectual property rights of Markabte or any third party.",
        IntPropFirstListItem:
          "You agree that all materials, products, and services provided on this website are the property of Markabte, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the Markabte’s intellectual property in any way, including electronic, digital, or new trademark registrations.",
        IntPropSecondListItem:
          "You grant Markabte a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish.",
        AdvParagraph:
          "Markabte reserves the right to display advertisements on your profile on the Website.",
        AltParagraph:
          "Markabte reserves the right, at its sole discretion, to modify or replace any part of this Agreement. It is your responsibility to check this Agreement periodically for changes. Your continued use of or access to the Website following the posting of any changes to this Agreement constitutes acceptance of those changes. Markabte may also, in the future, offer new services and/or features through the Website (including, the release of new tools and products) where such new features and/or services shall be subject to the terms and conditions of this Agreement.",
        TerminationParagraph:
          "Markabte may terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately. If you wish to terminate this Agreement or your Markabte.com account, you may simply discontinue using the Website. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.",
        CancellationParagraph:
          "This Website does not permit any payment cancelation for any plan purchase that has been completed.  Any purchased plans including both car credits & services will expire 90 days from the purchase date regardless if it has been used or not.",
        RefundFirstParagraph:
          "Once you purchase any plan you cannot refund any payment that you have already made. Notwithstanding the aforementioned, Markbate will refund your credits to display the inspection report in the event the report of any vehicle has been unlocked and it is sold within the following 24 hours from the time the report was unlocked.",
        RefundSecondParagraph:
          "Markabte will not at any time make any monetary refund and the refund stated above will be exclusively for Credit Refund.",
        IdemnificationParagraph:
          "You agree to indemnify and hold Markabte harmless, its contractors, and its licensors, and their respective directors, officers, employees and agents from and against any and all claims and expenses, including attorneys’ fees, arising out or connected to your use of the Website, including but not limited to your violation and breach to the terms of this Agreement.",

        "Click here to view the issue’s list of a certain view.":
          ".اﺿﻐﻂ ﻫﻨﺎ ﻟﻌﺮض ﻗﺎﺋﻤﺔ اﻟﺄﺿﺮار ﻟﻠﺠﻬﺔ اﻟﻤﺨﺘﺎرة",
        "Inspection Unavailable, diagnostic tools could not connect to car's computer":
          "الفحص غير متوفر ، أجهزة الفحص لم تتمكن من قراءة كمبيوتر المركبة",
        "Driving cycle inadequate, car must be driven for 70km and re-inspected for reliable results":
          "دورة القيادة غير كافية ، يجب قيادة السيارة لمسافة 70 كم وإعادة فحصها للحصول على نتائج موثوقة",
        "Voltage difference inconclusive, due to the effect of Abnormal resistance values":
          "فرق الجهد غير حاسم ، بسبب تأثير قيم المقاومة غير الطبيعية",
        "for sale": "للبيع",
        "max price": "أعلى سعر",
        "min price": "أقل سعر",

        "Card Information": "ﻣﻌﻠﻮﻣﺎت اﻟﺒﻄﺎﻗﺔ",
        "Name on Card*": "اﻟﺄﺳﻢ ﻋﻠﻰ اﻟﺒﻄﺎﻗﺔ*",
        "Card Number*": "رﻗﻢ اﻟﺒﻄﺎﻗﺔ*",
        "Expiry Date*": "ﺗﺎرﻳﺦ اﻟﺄﻧﺘﻬﺎء*",
        "You can find this 3-digit code at the back of your card.":
          "ﻳﻤﻜﻨﻚ إﻳﺠﺎد اﻟﺮﻣﺰ اﻟﻤﻜﻮن ﻣﻦ 3 أرﻗﺎم ﻓﻲ اﻟﺠﻬﺔ اﻟﺨﻠﻔﻴﺔ ﻣﻦ ﺑﻄﺎﻗﺘﻚ.",
        "CVV/CVC*": "CVV/CVC*",
        "*Card information are not stored in our databases.":
          "*ﻟﺎ ﻳﺘﻢ ﺗﺨﺰﻳﻦ ﻣﻌﻠﻮﻣﺎت اﻟﺒﻄﺎﻗﺔ ﻓﻲ ﻗﻮاﻋﺪ اﻟﺒﻴﺎﻧﺎت اﻟﺨﺎﺻﺔ ﺑﻨﺎ.",
        "Total Subscription Fees": "إﺟﻤﺎﻟﻲ رﺳﻮم اﻟﺎﺷﺘﺮاك",
        "*Subscribtion valid for": "*اﻟﻠﺎﺷﺘﺮاك ﺻﺎﻟﺢ ل",
        Pay: "ادفع",
        "Are you sure you want to unlock inspection details and seller’s information for this car?":
          "هل أنت متأكد أنك تريد كشف تقرير الفحص ومعلومات البائع لهذه السيارة؟",
        "Unlock Inspection": "كشف تقرير الفحص",
        "Remaining balance after use": "الرصيد المتبقي بعد الإستخدام",
        "I'm Interested": "أنا مهتم",
        "Unlock inspection report, connect with seller and much more.":
          "اكشف تقرير الفحص, تواصل مع البائع والمزيد.",
        "Connect with seller and much more.": "تواصل مع البائع والمزيد.",
        "Call Seller Anonymously": "اتصل بالبائع كمجهول",
        "Current Balance": "الرصيد الحالي",
        "Choose your desired action from below":
          "إختر الإجراء الذي تريده أدناه",
        "What do you want to do?": "ماذا تريد أن تفعل؟",
        "Limited Human Intervention": "ﺗﺪﺧﻞ ﺑﺸﺮي ﻣﺤﺪود",
        "Pay Less, Get More": "ادﻓﻊ أﻗﻞ,اﺣﺼﻞ ﻋﻠﻰ أﻛﺜﺮ",

        "Payment Summary": "ﻣﻠﺨﺺ اﻟﺪﻓﻊ",
        "*Car Credit valid for": "*اﻟﺎﺷﺘﺮاك ﺻﺎﻟﺢ ",
        "Discount Code": "ﻛﻮد اﻟﺨﺼﻢ",
        "Enter Discount Code": "ادﺧﻞ ﻛﻮد اﻟﺨﺼﻢ",
        "Plan Type": "ﻧﻮع اﻟﺒﺎﻗﺔ",
        "Price per car": "اﻟﺴﻌﺮﻟﻜﻞ ﻣﺮﻛﺒﺔ",
        "Discount Amount": "ﻗﻴﻤﺔ اﻟﺨﺼﻢ",
        "Total Amount": "إﺟﻤﺎﻟﻲ اﻟﻤﺒﻠﻎ",
        "Payment Method": "ﻃﺮﻳﻘﺔ اﻟﺪﻓﻊ",
        carsPaymentMethod: "ﻣﺮﻛﺒﺔ",
        "We are working hard to launch our service on Tuesday 31, May.":
          "ﻧﺤﻦ ﻧﻌﻤﻞ ﺟﺎﻫﺪﻳﻦ ﻟﺈﻃﻠﺎق ﻣﻮﻗﻌﻨﺎ ﻳﻮم الثلاثاء 31  أيار.",
        "Until then, you can explore the beta version of our website.":
          "ﻟﺤﻴﻦ ذﻟﻚ, ﻳﻤﻜﻨﻚ اﺳﺘﻜﺸﺎف اﻟﻨﺴﺨﺔ اﻟﺘﺠﺮﻳﺒﻴﺔ ﻣﻦ ﻣﻮﻗﻌﻨﺎ.",
        "Explore Beta Version": "اﺳﺘﻜﺸﻒ اﻟﻨﺴﺨﺔ اﻟﺘﺠﺮﻳﺒﻴﺔ",
        "Unlock inspection report and other services with one of our subcription plans.":
          "اﺳﺘﺨﺪم رﺻﻴﺪك ﻟﻠﺤﺼﻮل ﻋﻠﻰ ﺗﻘﺮﻳﺮ اﻟﻔﺤﺺ, ﻣﻌﻠﻮﻣﺎت اﻟﺒﺎﺋﻊ  و ﻏﻴﺮه.",
        Back: "اﻟرجوع",
        "Under Construction": "ﻗﻴﺪ البناء",
        "*Please make sure you have  an available mobile signal.":
          "*الرجاء التأكد من خدمة الإرسال في الموبايل.",
        "You will be connected with the seller anonymously via Markabte Call Portal.":
          "سوف يتم ايصالك بالبائع كمجهول عبر شبكة مركبتي.",
        "Call Me Now": "اتصل الآن",
        "Sorry, you can't call the seller past working hours. Please call during working hours from":
          "نعتذر لا يمكن الاتصال بالبائع خارج ساعات العمل. الرجاء الاتصال خلال ساعات الدوام الرسمية من",
        "How it works - Three easy steps": "آلية العمل - ثلاث خطوات بسيطة",
        Details: "تفاصيل",
        "Car Loading ...": "جاري تحميل السيارة ...",
        "No Notes": "ﻟﺎ ﻣﻠﺎﺣﻈﺎت",
        Notes: "ملاحظات",
        "Expiry Year*": "سنة الانتهاء*",
        "Expiry Month*": "شهر الانتهاء*",
        "Total Price": "إجمالي السعر",
        Save: "وفر",
        "Car Credits Pricings": "أسعار رصيد المركبات",
        "Choose the pricing that fits your needs":
          "اختر السعر الذي يلبي حاجتك أدناه",
        "One car and that’s it. Nothing more, nothing less.":
          "سيارة واحدة فقط.             لا أقل ولا أكثر.",
        "Two cars in mind? That’s fine. Unlock them both.":
          "محتار بين سيارتين؟ بسيطة! اكشف الفحصين.",
        "Want the best car? Unlock all your options.":
          "بدك أحسن سيارة؟ اكشف الفحص لكل خياراتك.",
        "Package Type": "نوع الباقة",
        "Total Package Fees": "إجمالي سعر الباقة",
        "*Car Credits valid for 3 months": "*رصيد صالح لمدة 3 أشهر",
        "Frequently Asked Questions": "اﻟﺄﺳﺌﻠﺔ اﻟﻤﺘﻜﺮرة",
        "View our FAQ about Markabte’s inspection below or contact us for any question.":
          "اﻃﻠﻊ ﻋﻠﻰ اﻟﺎﺳﺌﻠﺔ اﻟﻤﺘﻜﺮرة ﺣﻮل ﻓﺤﺺ ﻣﺮﻛﺒﺘﻲ أدﻧﺎه أو ﺗﻮاﺻﻞ ﻣﻌﻨﺎ.",
        "Markabte’s Inspection FAQ": "اﻟﺄﺳﺌﻠﺔ اﻟﻤﺘﻜﺮرة ﻟﻔﺤﺺ ﻣﺮﻛﺒﺘﻲ",
        "Have more questions?": "لديك المزيد من الاسئلة؟",
        "If you cannot find the answer to your question, contact us on":
          "اذا لم تتمكن من العثور على إجابة لسؤالك، تواصل معنا على",
        "We are at your service.": "نحن  في خدمتك.",
        "Sorry, the seller is not accepting calls for today. Please try again on another day.":
          "نعتذر, لا يتم استقبال مكالمات من قبل البائع اليوم. يرجى المحاولة في يوم آخر.",
        "No Cars in Your Garage!": "لا يوجد مركبات في كراجك!",
        "Browse and unlock cars in your garage.":
          "تصفح واكشف المركبات في كراجك.",
        " yr. ": " سنة ",
        " mos": " شهر",
        "* Visual report includes scratches with (Deep) severity only NOT with (Light) severity":
          "(* التقرير النظري يشمل الخدوش من الدرجة (العميقة) فقط و ليس الدرجة (الخفيفة",
        "* We do not take images of light scratches on the car's Exterior":
          "* لا نقوم بإرفاق صور للخدوش (الخفيفة) المدرجة بفحص الهيكل الخارجي ",
        "Not Included": "غير مشمول",
        "We scan the vehicle's on-board computer to diagnose & identify fault codes within the hybrid Battery system.":
          "نقوم بإجراء فحص خاص لكمبيوتر المركبة لتشخيص و تحديد رموز الأعطال الخاصة ببطارية الهايبرد.",
        "Contact Markabte Agent": "تواصل مع مندوب مركبتي",
        "Choose your desired action from below": "أختر طريقة التواصل أدناه",
        "Call Markabte Agent": "الاتصال مع مندوب مركبتي",
        "Text Markabte Agent via WhatsApp":
          "التواصل مع مندوب مركبتي عبر الواتس أب",
        "Request Call Back": "طلب معاودة الاتصال",
        "Text Markabte Agent": "إرسال رسالة نصية ",
        System: "نظام",
        "This Account belongs to the Dealer/ Officer network, please create a new User account with a different phone number.":
          "هذا الحساب ينتمي إلى شبكة التجار / مندوبي الفحص, يرجى إنشاء حساب مستخدم جديد برقم هاتف مختلف. ",
        "Phone Number or email already have account associated. Kindly try forgot password":
          "رقم الهاتف أو البريد الإلكتروني مستخدم, يرجى محاولة نسيت كلمة السر",
        Inspected: "مفحوصة",
        "Hybrid Battery": "بطارية الهايبرد",
        "(Lithium)": "(ليثيوم)",
        "Request Call Back": "طلب معاودة الاتصال",
        "Text Agent": "التواصل مع مندوب",
        "Seller Information": "معلومات البائع",
        "Indepedent Seller": "بائع مستقل",
        "markabte agent will call you shortly.":
          "سيتصل بك مندوب مركبتي قريباً.",
        "Markabte agent will call you shortly.":
          "سيتصل بك مندوب مركبتي قريباً.",
        "I am interested in": "أنا مهتم بـ",
        "with listing id": "اعلان رقم",
        "Tell Us About Your Car": "أخبرنا عن سيارتك",
        "Set Appointment for Inspection": "حدد موعد الفحص و المعاينة",
        "Advertising and Receiving Buyers": "نعلن و نستقبل المشترين",
        "Get The Best Sell Offer": "أحصل على أفضل عرض لبيع سيارتك",
        "Sell Your Car With Markabte": "بيع سيارتك مع مركبتي",
        "Enter your car’s information and set your price or we will help you with valuation.":
          "أدخل معلومات سيارتك و حدد سعرك أو اطلب المساعدة في تحديد سعرها.",
        "We will send an inspection officer at the time and place suitable for you.":
          "نرسل مندوب الفحص في الوقت و المكان المناسب لك .",
        "We will assign a specialist to answer all phone calls and negotiate with buyers.":
          "نخصص لك عميل للإجابة على جميع المكالمات و الاستفسارات و التفاوض مع المشترين.",
        "We coordinate with the buyer and help you with ownership transfer papers.":
          "نقوم بالتنسيق مع المشتري و مساعدتك في تجهيز أوراق نقل الملكية.",
        "The New Way to Sell Your Car.": "الطريقة الجديدة لبيع سيارتك.",
        "Loading, Please wait.": "جاري التحميل, يرجى الانتظار.",
        "Contact Sales Agent": "تواصل مع عميل مركبتي",
        Call: "اتصل",
        "Call Sales Agent": "الاتصال مع عميل مركبتي",
        "Text Sales Agent via WhatsApp": "التواصل مع عميل مركبتي عبر الواتس أب",
        "Text Sales Agent": "إرسال رسالة نصية",
        "Do you have any questions?": "لديك استفسارات؟",
        "Contact your dedicated sales agent": "تواصل مع عميل مركبتي المخصص لك",
        "Contact Sales Agent": "تواصل مع عميل مركبتي",
        "Your are not signed in": "لم يتم تسجيل دخولك",
        "You must be signed in to view the Seller inspection report":
          "يجب تسجيل الدخول لعرض تقرير الفحص",
        "Selling Process": "آلية البيع",
        "Buying Process": "آلية الشراء",
        "Find Your Suitable Car": "ابحث عن السيارة المناسبة لك",
        "Benefits of Using Markabte": "فوائد الشراء من مركبتي",
        "Highest Saving & Best Service!": "أعلى توفير و أفضل خدمة!",
        "Choose the package that best suits your needs.":
          "اختر الباقة التي تناسب احتياجاتك.",
        "Charge Your Credit Balance": "اشحن رصيد حسابك",
        "Choose the package that best fits your needs.":
          "اختر الباقة الأنسب لاحتياجاتك.",
        "Tell Us About Your Car": "أخبرنا عن سيارتك",
        "Enter your car’s information and set your price or we will help you with valuation.":
          "أدخل معلومات سيارتك و حدد سعرك أو اطلب المساعدة في تحديد سعرها.",
        "Set Appointment for Inspection": "حدد موعد الفحص و المعاينة",
        "We will send an inspection officer at the time and place suitable for you.":
          "نرسل مندوب الفحص في الوقت و المكان المناسب لك .",
        "We Receive Calls From Buyers": "نستقبل مكالمات المشترين",
        "We will assign a specialist to answer all phone calls and negotiate with buyers.":
          "نخصص لك عميل للإجابة على جميع المكالمات و الاستفسارات و التفاوض مع المشترين.",
        "Get The Best Sell Offer": "أحصل على أفضل عرض لبيع سيارتك",
        "We coordinate with the buyer and help you with ownership transfer papers.":
          "نقوم بالتنسيق مع المشتري و مساعدتك في تجهيز أوراق نقل الملكية.",
        "The Best Way to": "الطريقة الأفضل",
        Cars: "السيارات",
        Sell: "لبيع",
        and: "و",
        Buy: "شراء",
        "Search for a Car": "ابحث عن سيارة",
        "Sell Your Car": "بيع سيارتك",
        "Inspection Sample Reports >": "عينات تقرير الفحص >",
        "25 JD": "25 د.أ",
        "Starting From": "ابتداءً من",
        "Per Car": "لكل سيارة",

        "Car Information": "معلومات السيارة",
        "Basic Car Details": "معلومات السيارة الأساسية",
        "Personal Information": "معلومات الشخصية",
        "Personal Details": "معلوماتك الشخصية",
        "Sell My Car Request": "تأكيد بيانات طلب بيع سيارتي",
        "Edit Information": "تعديل المعلومات",
        "Request Call Back": "طلب معاودة الاتصال",
        Continue: "المتابعة",
        "Yes (Please attach car frame image)":
          "نعم (الرجاء إرفاق صورة فحص الشاصي أدناه)",
        No: "No",
        "Upload Image": "حمّل صورة",
        "How much do you want to sell your car for?": "ما هو سعر بيع سيارتك؟",
        "Mileage*": "العداد*",
        "Unit of Measurement*": "وحدة القياس*",
        "City*": "المدينة*",
        "Area*": "المنطقة*",
        "Full Name*": "الأسم الكامل*",
        "Year*": "السنة*",
        "Did you get your car frame inspected?": "هل قمت بفحص الشاصي لسيارتك؟",

        "Car Price": "سعر السيارة:",
        "I do not know": "لا أعلم",
        No: "لا",
        "Ex: Khalda": "مثال خلدا",
        "Car Frame": "فحص الشصي",
        "Do you want to sell your car?": "هل تريد بيع سيارتك؟",
        "Markabte will handle the matter.": "مركبتي ستتولى المهمة.",
        "Sell Your Car With Markabte": "بيع سيارتك مع مركبتي",

        "Best Price": "أفضل سعر",
        "Set your price and receive offers from buyers.":
          "حدد سعرك و أحصل على عروض البيع من المشترين.",
        "Fast Sell": "بيع سريع",
        "We inspect and advertise your car to reach the largest number of buyers.":
          "نفحص و نسوق سيارتك لتصل لأكبر عدد من المشترين الجادين.",
        "Risk Free": "بلا مخاطر",
        "You will only pay on successful car sale only.":
          "ﻟﻦ ﺗﻘﻮم ﺑﺎﻟﺪﻓﻊ اﻟﺎ ﻋﻨﺪ عملية اﻟﺒﻴﻊ اﻟﻨﺎﺟﺤﺔ.",
        "Easy & Convienent": "سهل و مريح",
        "Keep your car and a specialist will take care of selling it on your behalf.":
          "احتفظ بسيارتك و سيتولى عميل مختص عملية بيعها بالنيابة عنك.",
        "We will call you back ٍsoon.": "سيتم الاتصال بك في أقرب وقت.",
        "Loading, Please wait.": "جاري التحميل, يرجى الانتظار.",
        "Contact Sales Agent": "تواصل مع عميل مركبتي",
        Call: "اتصل",
        "Call Sales Agent": "الاتصال مع عميل مركبتي",
        "Text Sales Agent via WhatsApp": "التواصل مع عميل مركبتي عبر الواتس أب",
        "Text Sales Agent": "إرسال رسالة نصية",
        "Do you have any questions?": "لديك استفسارات؟",
        "Contact your dedicated sales agent": "تواصل مع عميل مركبتي المخصص لك",
        "Contact Sales Agent": "تواصل مع عميل مركبتي",
        "Your are not signed in": "لم يتم تسجيل دخولك",
        "You must be signed in to view the Seller inspection report":
          "يجب تسجيل الدخول لعرض تقرير الفحص",
        "Not Specified": "غير محدد",
        "Not valid": "غير متوفر",
        Unlock: "اكشف",
        "Contact Markabte Agent directly.": "تواصل مع مندوب مركبتي مباشرة.",
        "Contact the seller directly.": "تواصل مع البائع مباشرة.",
        "Unlock inspection report and contact the seller.":
          "اكشف تقرير الفحص و تواصل مع البائع.",
        "Choose method of contact below": "أختر طريقة التواصل أدناه:",
        "Please charge your balance to unlock inspection reports.":
          "الرجاء شحن رصيدك لتتمكن من كشف تقرير الفحص.",
        "Call me": "اتصل بي",
        "Send Whatsapp Text": "إرسال رسالة واتس أب",
        "Charge Credit": "اشحن رصيد",
        "Please wait, we will call you now.":
          "الرجاء الانتظار, سنقوم بالاتصال بك بالحال.",
        "Similar Cars": "سيارات مشابهة",
        "Recently Listed Cars": "سيارات مضافة حديثاً",
        applyButton: "ابحث",
        "All filters are Cleared.": "تم حذف جميع خيارات الفلترة.",
        City: "المدينة",
        Area: "المنطقة",
        Make: "الصنع",
        Model: "الموديل",
        Year: "السنة",
        Date: "التاريخ",
        Time: "الوقت",
        "Buyer Name": "اسم المشتري",
        "Buyer Phone Number": "رقم هاتف المشتري",
        "Seller Name": "اسم البائع",
        "Seller Phone Number": "رقم هاتف البائع",
        "Book now!": "احجز الآن!",
        "Any Questions?": "لديك استفسارات؟",
        "Contact our technical support specialist": "تواصل مع مختص الدعم الفني",
        "Call back request": "طلب إعادة اتصال",
        "The technical support specialist will contact you as soon as possible.":
          ".سيقوم مختص الدعم الفني بالتواصل معك في أقرب وقت ممكن",
        "Phone number": "رقم الهاتف",
        Approve: "الموافقة",
        "Buyer Info": "معلومات المشتري",
        "Seller Info": "معلومات البائع",
        "Book Appointment": "احجز موعد",
        "Your Name": "اسمك",
        "Your Phone Number": "رقم هاتفك",
        Package: "نوع الفحص",
        "Available Times": "الأوقات المتاحة",

        "Technical support will call you shortly.":
          "سيتواصل معك مختص الدعم الفني قريبا.",
        "I have an inquiry regarding": "لدي استفسار بخصوص",
        "Questions and Inquiries": "الأسئلة و الإستفسارات",
        "Any question or inquiry? Contact our technical support specialist.":
          "لديك اي سؤال أو استفسار؟ تواصل مع مختص الدعم الفني.",
        "Contact Technician": "تواصل مع مختص الدعم الفني",
        "We conduct a visual inspection and measure the dimension of the frame to spot any frame misalignment, visible damages, or evidence of previous repairs.":
          "نقوم بأجراء فحص نظري و قياس أبعاد الشاصي للكشف عن أي انحراف في أجزاء الشاصي، أضرار ظاهرة، أو آثار إصلاح سابقة.",
        "Minor Note": "ملاحظة بسيطة",
        "Major Note": "ملاحظة كبيرة",
        "Enter your phone number and you will be contacted shortly":
          "ادخل رقم هاتفك و سنقوم بالاتصال بك في اقرب وقت",
        "Please check you'r phone number": "الرجاء التأكد من رقم الهاتف",
        "Evidence Based Report": "تقرير موثق بأدلة",
        "Detailed & User Freindly": "مفصل و سهل الاستخدام",
        "We identify faults in electrical systems by scanning the car's computer, test their performance with advanced tools, and conduct a visual inspection of fuse boxes and electrical harness.":
          "نقوم بتحديد أعطال الأنظمة الكهربائية من خلال فحص كمبيوتر المركبة, اختبار أدائها بأجهزة مختصة, وإجراء فحص نظري لصناديق الفيوزات و الجدلة الكهربائية.",
        "We identify engin and transmission faults by scanning the car's computer, test the engin cooling system, and conduct a visual inspection to identify damages or oil / fluid leaks.":
          "نقوم بتحديد أعطال المحرك و ناقل الحركة من خلال فحص كمبيوتر المركبة, اختبار نظام تبريد المحرك, وإجراء فحص نظري لتحديد الأضرار أو تهريب الزيوت / السوائل .",
        inspect: "لفحص",
        "View Sample Report": "عينات تقرير الفحص",
        "We determine the condition of the EV system (high voltage) based on the results of the system’s computer scan, Battery cell voltage test under load, the cooling system test, and the visual inspection (damages or leaks or previous evidence repairs).":
          "نحدد حالة النظام الكهربائي (الجهد العالي) بناء على نتائج فحص كمبيوتر النظام، اختبار فولتية الخلايا على الحمل، اختبار نظام التبريد، و الفحص النظري (أضرار أو تهريب أو آثار إصلاح)",

        "We determine the condition of suspension and steering system based on the results of the system’s computer scan, and the visual inspection (damages or leaks or wear) of all the parts and seals.":
          "نحدد حالة أنظمة التوجيه والتعليق بناء على نتائج فحص كمبيوتر النظام، الفحص النظري (أضرار أو تهريب أو استهلاك) لجميع الأجزاء و جلود نظام التعليق.",
        "We determine the condition of the braking systems based on the brake fluid moisture test, scanning the braking systems computer, and the visual inspection (damages or leaks or high consumption) of all the braking system components.":
          "نحدد حالة أنظمة المكابح بناء على فحص رطوبة سائل المكابح، فحص كمبيوتر أنظمة المكابح، و الفحص نظري  لجميع أجزاء نظام المكابح (تهريب أو أضرار أو استهلاك عالي)",
        "We scan the computer to identify faults in the safety systems, and perform a visual inspection of the airbags to check their condition and detect any case of airbag bypass by using resistors.":
          "نقوم بإجراء فحص كمبيوتر لتحديد أعطال أنظمة الأمان، و فحص الوسائد الهوائية نظرياً للتأكد من حالتها و للكشف عن أي حالات تلاعب عن طريق تركيب مقاومات",
        "We determine the condition of the Hybrid system (high voltage) based on the results of the system's computer scan, Battery cell voltage test under load, the cooling system test, and the visual inspection (damages or leaks or previous evidence repairs).":
          "نحدد حالة نظام الهايبرد (الجهد العالي) بناء على نتائج فحص كمبيوتر النظام، اختبار فولتية الخلايا على الحمل، اختبار نظام التبريد، و الفحص النظري (أضرار أو تهريب أو آثار إصلاح)",
        "Inspection type": "نوع الفحص",
        "No. of points": "عدد نقاط الفحص",
        Result: "النتيجة",
        "Inspection point": "نقطة فحص",
      },
    },
  },
  fallbackLng: localStorage.getItem("language") ?? "ar",
  // debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
